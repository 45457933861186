<template>
  <div class="map-component-container">
    <div ref="map" class="map-component"></div>
  </div>
</template>
<script>
import {mapState} from "vuex";
import chinaPolygonJson from "@/assets/js/china.json";
import chinaLineJson from "@/assets/js/chinaBound.json";
import RCPolygonJson from "@/assets/js/boundary.json";
import PictureLink from "../MapComonents/PictureLink";
// import BMapGroundOverlay from "@/assets/js/BMapGroundOverlay";

export default {
  props: {
    hideChina: Boolean,
    stopDefaultFarmClick: {
      type: Boolean,
      default: false,
    },
    center: {
      type: Array,
      default() {
        return [105.52314042411466, 29.475651579673745];
        // return [117.19635, 36.24093];
      },
    },
    zoom: {
      type: Number,
      default: 11,
      // default: 5,
    },
    // 加载区域范围
    boundaryName: {
      type: String,
      default: "重庆市荣昌区",
    },
    farmFilterDefault: Set,
    showPicture: {
      type: Boolean,
      default: false,
    },
    useType: {
      default: 1,
    },
  },
  data() {
    return {
      map: null,
      farmFilter: new Set(),
      // 地图上基地点集合
      farmDotMap: new Map(),
      // 已经加载了设备点的基地集合
      farmSitesReadyMap: new Map(),
      // 已经加载了基地方位的基地集合
      farmAreaReadyMap: new Map(),
      // 已经加载了处理设备的基地结合
      farmDeviceReadyMap: new Map(),
      // 已经加载了处理设施的基地结合
      farmDealDeviceReadyMap: new Map(),

      westNorthArea: new Set([
        "新疆维吾尔自治区",
        "青海省",
        "甘肃省",
        "宁夏回族自治区",
        "陕西省",
      ]),
      westSouthArea: new Set([
        "西藏自治区",
        "四川省",
        "云南省",
        "重庆市",
        "云南省",
        "贵州省",
      ]),
      northArea: new Set([
        "内蒙古自治区",
        "山西省",
        "河北省",
        "北京市",
        "天津市",
      ]),
      centerArea: new Set(["河南省", "湖北省", "湖南省"]),
      southArea: new Set([
        "广西壮族自治区",
        "广东省",
        "海南省",
        "香港特别行政区",
        "澳门特别行政区",
      ]),
      eastNorthArea: new Set(["黑龙江省", "吉林省", "辽宁省"]),
      eastArea: new Set([
        "山东省",
        "江苏省",
        "安徽省",
        "浙江省",
        "江西省",
        "福建省",
        "台湾省",
        "上海市",
      ]),

      areaRemark: new Map(),

      ignoreClickPoint: new Set(),
      readyPositionLeft: [],
      readyPositionRight: [],
      marker1List: [],
      iconInterVal: null
    };
  },
  computed: {
    ...mapState({
      farmReady: (state) => state.map.farmReady,
      farmList: (state) => state.map.farmList,
    }),
  },
  methods: {
    setFilter(filter) {
      this.farmFilter = filter;
      let zoom = this.map.getZoom();
      if (zoom > 13) return;
      for (let i = 0; i < this.farmList.length; i++) {
        let farm = this.farmList[i];
        if (filter && filter.size > 0 && !filter.has(farm.siteType)) {
          this.farmDotMap.get(farm.sourceId) &&
          this.farmDotMap.get(farm.sourceId).hide();
        } else {
          this.farmDotMap.get(farm.sourceId) &&
          this.farmDotMap.get(farm.sourceId).show();
        }
      }
    },
    //   初始化地图
    initMap() {
      return new Promise((resolve) => {
        this.map = new BMapGL.Map(this.$refs.map, {
          // mapType: BMAP_EARTH_MAP,
        });
        this.map.setMapStyleV2({
          styleId: "6964c6e423f1d76f124ad69b42b307cf",
        });

        this.map.centerAndZoom(new BMapGL.Point(...this.center), this.zoom);
        this.map.enableScrollWheelZoom(true);
        resolve();
      });
    },
    // 加载区域范围
    loadBoundary() {
      if (!this.boundaryName) return;
      if (this.boundaryName == "重庆市荣昌区") {
        this.addRC();
        return;
      }
      let boundary = new BMapGL.Boundary();
      boundary.get(this.boundaryName, (e) => {
        if (!e) return;
        if (!e.boundaries || e.boundaries.length === 0) return;
        // for (let i = 0; i < e.boundaries.length; i++) {
        let point = e.boundaries[0];
        let points = point.split(";").map((item) => {
          let it = item.split(",");
          return new BMapGL.Point(it[0], it[1]);
        });
        let polygon = new BMapGL.Polygon(points, {
          strokeColor: "#08dddd",
          fillColor: "#08dddd",
          strokeWeight: 2,
          strokeOpacity: 1,
          fillOpacity: 0.3,
          strokeStyle: "solid",
        });
        this.map.addOverlay(polygon);
        // }
      });
    },
    // 隐藏国外
    hideOverChina() {
      let maskPoints = [
        new BMapGL.Point(0, -90),
        new BMapGL.Point(0, 90),
        new BMapGL.Point(180, 90),
        new BMapGL.Point(180, -90),
      ];
      let maskPolygon = new BMapGL.Polygon(maskPoints, {
        // fillColor: "radial-gradient(circle, #125E7C 0%, #082C3A 100%)",
        fillColor: "#18579F",
        fillOpacity: 1,
      });
      this.map.addOverlay(maskPolygon);
      this.map.addEventListener("zoomend", () => {
        let zoom = this.map.getZoom();
        let mapType = this.map.getMapType();
        if (zoom > 13) {
          mapType !== BMAP_EARTH_MAP && this.map.setMapType(BMAP_EARTH_MAP);
          maskPolygon.hide();
        } else {
          mapType !== BMAP_NORMAL_MAP && this.map.setMapType(BMAP_NORMAL_MAP);
          maskPolygon.show();
        }
      });
      if (this.hideChina) return;
      this.getAreaRemark();
      for (let i = 0; i < chinaPolygonJson.features.length; i++) {
        let bound = chinaPolygonJson.features[i];
        let boundColor = this.areaColor(bound.properties.name);
        if (bound.geometry.type == "MultiPolygon") {
          for (let j = 0; j < bound.geometry.coordinates.length; j++) {
            let subPolygon = bound.geometry.coordinates[j];
            let subPolygonPoints = [];
            for (let k = 0; k < subPolygon.length; k++) {
              let points = subPolygon[k].map(
                  (item) => new BMapGL.Point(item[0], item[1])
              );
              subPolygonPoints.push(points);
            }
            let polygon = new BMapGL.Polygon(subPolygonPoints, {
              strokeOpacity: 0,
              fillOpacity: 1,
              fillColor: boundColor,
            });
            this.map.addEventListener("zoomend", () => {
              let zoom = this.map.getZoom();
              if (zoom > 13) {
                polygon.hide();
              } else {
                polygon.show();
              }
            });
            polygon.addEventListener("click", (e) => {
              if (this.useType == 1) return;
              this.showAreaMessage(bound.properties.name);
            });
            this.map.addOverlay(polygon);
          }
        } else if (bound.geometry.type == "Polygon") {
          let subPolygon = bound.geometry.coordinates;
          let subPolygonPoints = [];
          for (let k = 0; k < subPolygon.length; k++) {
            let points = subPolygon[k].map(
                (item) => new BMapGL.Point(item[0], item[1])
            );
            subPolygonPoints.push(points);
          }
          let polygon = new BMapGL.Polygon(subPolygonPoints, {
            strokeOpacity: 0,
            fillOpacity: 1,
            fillColor: boundColor,
          });
          this.map.addEventListener("zoomend", () => {
            let zoom = this.map.getZoom();
            if (zoom > 13) {
              polygon.hide();
            } else {
              polygon.show();
            }
          });
          polygon.addEventListener("click", (e) => {
            if (this.useType == 1) return;
            if (this.ignoreClickPoint.has("" + e.pixel.x + e.pixel.y)) return;
            showAreaMessage(bound.properties.name);
          });
          this.map.addOverlay(polygon);
        }
      }
      for (let i = 0; i < chinaLineJson.features.length; i++) {
        let province = chinaLineJson.features[i];
        if (province.geometry.type == "LineString") {
          let tempPoints = province.geometry.coordinates.map(
              (item) => new BMapGL.Point(item[0], item[1])
          );
          let polyline = new BMapGL.Polyline(tempPoints, {
            strokeColor: "#FFF",
            strokeWeight: 1,
          });
          this.map.addEventListener("zoomend", () => {
            let zoom = this.map.getZoom();
            if (zoom > 13) {
              polyline.hide();
            } else {
              polyline.show();
            }
          });
          this.map.addOverlay(polyline);
        } else if (province.geometry.type == "MultiLineString") {
          for (let j = 0; j < province.geometry.coordinates.length; j++) {
            let tempPoints = province.geometry.coordinates[j].map(
                (item) => new BMapGL.Point(item[0], item[1])
            );

            let polyline = new BMapGL.Polyline(tempPoints, {
              strokeColor: this.useType == 1 ? "#52ffee" : "#fff",
              strokeWeight: 1,
            });
            this.map.addEventListener("zoomend", () => {
              let zoom = this.map.getZoom();
              if (zoom > 13) {
                polyline.hide();
              } else {
                polyline.show();
              }
            });
            this.map.addOverlay(polyline);
          }
        }
      }
    },
    getAreaRemark() {
      this.$post(this.$api.REGION_MAP.LIST, {
        show: "0",
        pageNo: 1,
        pageSize: 999,
      }).then((res) => {
        let list = res.list;
        list.forEach((item) => {
          this.areaRemark.set(item.name, item.content);
        });
      });
    },
    showAreaMessage(areaName) {
      let content;
      if (this.westNorthArea.has(areaName)) {
        content = this.areaRemark.get("西北地区");
      }
      if (this.westSouthArea.has(areaName)) {
        content = this.areaRemark.get("西南地区");
      }
      if (this.northArea.has(areaName)) {
        content = this.areaRemark.get("华北地区");
      }
      if (this.centerArea.has(areaName)) {
        content = this.areaRemark.get("华中地区");
      }
      if (this.southArea.has(areaName)) {
        content = this.areaRemark.get("华南地区");
      }
      if (this.eastNorthArea.has(areaName)) {
        content = this.areaRemark.get("东北地区");
      }
      if (this.eastArea.has(areaName)) {
        content = this.areaRemark.get("华东地区");
      }
      // if (content) {
      //   this.$Modal.info({
      //     width: 720,
      //     content: `<div style="width:100%;max-height:calc(100vh - 300px);overflow:auto">${content}</div>`,
      //   });
      // }
    },
    // 确定地区颜色
    areaColor(name) {
      // if (this.westNorthArea.has(name)) return "#D29B2D";
      // if (this.westSouthArea.has(name)) return "#3C9384";
      // if (this.northArea.has(name)) return "#70632D";
      // if (this.centerArea.has(name)) return "#C05934";
      // if (this.southArea.has(name)) return "#3464D2";
      // if (this.eastNorthArea.has(name)) return "#254075";
      // if (this.eastArea.has(name)) return "#3685E0";
      if (this.westNorthArea.has(name)) return "#5490D5";
      if (this.westSouthArea.has(name)) return "#2E77AF";
      if (this.northArea.has(name)) return "#49A3DF";
      if (this.centerArea.has(name)) return "#6FBCFF";
      if (this.southArea.has(name)) return "#3B64C2";
      if (this.eastNorthArea.has(name)) return "#1A68AA";
      if (this.eastArea.has(name)) return "#3685E0";
    },
    addRC() {
      for (let i = 0; i < RCPolygonJson.features.length; i++) {
        let bound = RCPolygonJson.features[i];
        if (bound.geometry.type == "MultiPolygon") {
          for (let j = 0; j < bound.geometry.coordinates.length; j++) {
            let subPolygon = bound.geometry.coordinates[j];
            let subPolygonPoints = [];
            for (let k = 0; k < subPolygon.length; k++) {
              let points = subPolygon[k].map(
                  (item) => new BMapGL.Point(item[0], item[1])
              );
              subPolygonPoints.push(points);
            }
            let polygon = new BMapGL.Polygon(subPolygonPoints, {
              strokeOpacity: 1,
              strokeWeight: 1,
              strokeColor: "#228cd6",
              fillOpacity: 1,
              fillColor: "#024e88",
            });
            this.map.addEventListener("zoomend", () => {
              let zoom = this.map.getZoom();
              if (zoom > 13) {
                polygon.hide();
              } else {
                polygon.show();
              }
            });
            this.map.addOverlay(polygon);
          }
        } else if (bound.geometry.type == "Polygon") {
          let subPolygon = bound.geometry.coordinates;
          let subPolygonPoints = [];
          for (let k = 0; k < subPolygon.length; k++) {
            let points = subPolygon[k].map(
                (item) => new BMapGL.Point(item[0], item[1])
            );
            subPolygonPoints.push(points);
          }
          let polygon = new BMapGL.Polygon(subPolygonPoints, {
            strokeOpacity: 1,
            strokeWeight: 1,
            strokeColor: "#228cd6",
            fillOpacity: 1,
            fillColor: "#024e88",
          });
          this.map.addEventListener("zoomend", () => {
            let zoom = this.map.getZoom();
            if (zoom > 13) {
              polygon.hide();
            } else {
              polygon.show();
            }
          });
          this.map.addOverlay(polygon);
        }
      }
    },
    // 加载所有基地点
    addFarmDot() {
      if (!this.farmReady) {
        this.$store.dispatch("getAllFarms").then((list) => {
          this.addFarmDotDo(list);
        });
      } else {
        this.addFarmDotDo(this.farmList);
      }
    },
    addFarmDotDo(list) {

      for (let i = 0; i < list.length; i++) {
        let item = list[i];
        if (!item.mapCenterLocation) continue;
        let [lng, lat] = item.mapCenterLocation.split(",");

        // 加载图标
        let icon = new BMapGL.Icon(
            `/imgs/map/sitetype${item.siteType}.png`,
            new BMapGL.Size(24, 24),
            {
              anchor: new BMapGL.Size(12, 24),
            }
        );
        let point = new BMapGL.Point(+lng, +lat);
        let marker = new BMapGL.Marker(point, {
          icon,
          offset: new BMapGL.Size(0, 12),
        });
        // 加载名称
        let label = new BMapGL.Label(
            `<div style='transform:translateX(-50%);color:#fff;font-size: 16px;letter-spacing: 1px;'>${item.mapName}</div>
          `,
            {
              position: point,
              offset: new BMapGL.Size(0, 4),
            }
        );
        label.setStyle({
          border: "none",
          background: "none",
        });
        label.hide();
        this.map.addOverlay(label);
        marker.addEventListener("mouseover", () => label.show());
        marker.addEventListener("mouseout", () => label.hide());
        marker.addEventListener("click", (e) => {
          if (this.useType == 1) {
            this.$root.$emit("clickRoom", item);
            return;
          }
          this.ignoreClickPoint.add("" + e.pixel.x + e.pixel.y);
          this.farmClick(item);
        });

        /* ------------------ 加载图片预览 -------------------- */
        let pictureLink;
        if (this.showPicture && item.farmImgUrl) {
          let url = item.servicePath + item.farmImgUrl;
          let {offsetX, offsetY} = this.getImagePosition(item);

          pictureLink = new PictureLink(point, url, {
            offset: [offsetX, offsetY],
          });
          pictureLink.addEventListener("mouseover", () => label.show());
          pictureLink.addEventListener("mouseout", () => label.hide());
          pictureLink.addEventListener("click", () => {
            this.$post(this.$api.BASE_MANAGE.INFO, {
              id: item.sourceId,
            }).then((res) => {
              let remark = res.remark;
              if (this.useType == 1) {
                this.$root.$emit("clickRoom", item);
              } else {
                this.$Modal.info({
                  content: remark,
                  width: 720,
                });
              }
            });
          });
          this.map.addOverlay(pictureLink);
        }
        if (
            this.farmFilter &&
            this.farmFilter.size > 0 &&
            !this.farmFilter.has(item.siteType)
        ) {
          marker.hide();
          pictureLink && pictureLink.hide();
        }
        this.map.addOverlay(marker);
        this.farmDotMap.set(item.sourceId, marker);
        this.map.addEventListener("zoomend", () => {
          let zoom = this.map.getZoom();
          if (zoom > 13) {
            marker.hide();
            pictureLink && pictureLink.hide();
          } else if (
              this.farmFilter &&
              this.farmFilter.size > 0 &&
              !this.farmFilter.has(item.siteType)
          ) {
            marker.hide();
            pictureLink && pictureLink.hide();
          } else {
            marker.show();
            pictureLink && pictureLink.show();
          }
        });
      }
    },
    getImagePosition(item) {
      // let rc = [105.492184,29.506659]
      // let mapCenterLocation = item.mapCenterLocation.split(',')
      // let offsetX
      // let offsetY
      // if (mapCenterLocation[0] < +rc[0]) {
      //   offsetX = -100
      // } else {
      //   offsetX = 100
      // }
      // if (mapCenterLocation[1] < +rc[1]) {
      //   offsetY = 10
      // } else {
      //   offsetY = -10
      // }
      // if (offsetX < 0) {
      //   offsetX += (Math.abs(mapCenterLocation[0] - rc[0]) > 3 ? 1 : Math.abs(mapCenterLocation[0] - rc[0])) * offsetX - 20
      // } else {
      //   offsetX += (Math.abs(mapCenterLocation[0] - rc[0]) > 3 ? 1 : Math.abs(mapCenterLocation[0] - rc[0])) * offsetX + 20
      // }
      // if (offsetY < 0) {
      //   offsetY += (Math.abs(mapCenterLocation[0] - rc[0]) > 3 ? 1 : Math.abs(mapCenterLocation[0] - rc[0])) * offsetY - 50
      // } else {
      //   offsetY += (Math.abs(mapCenterLocation[0] - rc[0]) > 3 ? 1 : Math.abs(mapCenterLocation[0] - rc[0])) * offsetY + 50
      // }
      // return {offsetX, offsetY}

      if (item.mapName == "新疆福纳斯生物工程有限公司") {
        return {offsetX: 100, offsetY: -100};
      }
      if (item.mapName == "荣昌远觉工厂") {
        return {offsetX: -100, offsetY: -80};
      }
      if (item.mapName == "新疆肥力沃生物工程有限公司") {
        return {offsetX: -250, offsetY: -120};
      }
      if (item.mapName == "荣昌双河工厂") {
        return {offsetX: -300, offsetY: -50};
      }
      if (item.mapName == "四川南部工厂") {
        return {offsetX: -50, offsetY: -180};
      }
      if (item.mapName == "荣昌铜鼓工厂") {
        return {offsetX: 200, offsetY: -20};
      }
      if (item.mapName == "荣昌仁义工厂") {
        return {offsetX: 100, offsetY: 50};
      }
      if (item.mapName == "新疆农神生物工程有限公司") {
        return {offsetX: -100, offsetY: 50};
      }
      if (item.mapName == "吉林农神生物工程有限公司") {
        return {offsetX: 100, offsetY: -100};
      }
      if (item.mapName == "安岳农神生物工程有限公司") {
        return {offsetX: -150, offsetY: -180};
      }
      if (item.mapName == "四川农神生物工程有限公司") {
        return {offsetX: 20, offsetY: -280};
      }
      if (item.mapName == "重庆农神生物工程有限公司") {
        return {offsetX: 50, offsetY: -180};
      }
      if (item.mapName == "农神生物（重庆云阳县）有限公司") {
        return {offsetX: 70, offsetY: -100};
      }
      if (item.mapName == "合肥农神生物工程有限公司") {
        return {offsetX: 80, offsetY: -130};
      }
      if (item.mapName == "贵州农神茗城生物工程有限公司") {
        return {offsetX: 150, offsetY: 140};
      }
      if (item.mapName == "贵阳清镇市农神生物工程有限公司") {
        return {offsetX: 50, offsetY: 150};
      }
      if (item.mapName == "贵州农神实业有限公司（贵州·兴义市）") {
        return {offsetX: 20, offsetY: 200};
      }
      if (item.mapName == "农神生物（贵州纳雍县）有限公司") {
        return {offsetX: -100, offsetY: 200};
      }
      if (item.mapName == "农神生物（四川叙永县）有限公司") {
        return {offsetX: -400, offsetY: -30};
      }
      if (item.mapName == "云南农神生物工程有限公司") {
        return {offsetX: -180, offsetY: 80};
      }
      if (item.mapName == "农神生物（四川会理县）有限公司") {
        return {offsetX: -250, offsetY: -10};
      }
      return {offsetX: 0, offsetY: 0};
      // this.readyPosition;
      // let offsetX, offsetY;
      // if (this.readyPositionLeft.length > this.readyPositionRight.length) {
      //   offsetX = 100;
      //   offsetY =
      //     this.readyPositionRight.length > 0
      //       ? this.readyPositionRight[this.readyPositionRight.length - 1]
      //           .offsetY + 90
      //       : -300;
      //   this.readyPositionRight.push({
      //     offsetX,
      //     offsetY,
      //   });
      // } else {
      //   offsetX = -100;
      //   offsetY =
      //     this.readyPositionLeft.length > 0
      //       ? this.readyPositionLeft[this.readyPositionLeft.length - 1]
      //           .offsetY + 90
      //       : -300;
      //   this.readyPositionLeft.push({
      //     offsetX,
      //     offsetY,
      //   });
      // }

      // return {
      //   offsetX,
      //   offsetY,
      // };
    },
    // 基地点击
    farmClick(farm, stopPop = false) {
      if (!stopPop) this.$emit("farm-click", farm);
      if (this.stopDefaultFarmClick) return;
      // 基地点状态
      let siteStatus = false,
          // 基地范围状态
          areaStatus = false,
          // 处理设备状态
          deviceStatus = false,
          // 处理设施状态
          dealDeviceStatus = false;
      let callback = () => {
        if (siteStatus && areaStatus && deviceStatus && dealDeviceStatus) {
          this.flyToFarm(farm);
        }
      };
      console.log(farm);
      // 尚未加载该基地设备点
      if (!this.farmSitesReadyMap.get(farm.sourceId)) {
        // 添加基地传感器/设备点
        this.addFarmSites(farm).then(() => {
          siteStatus = true;
          callback();
        });
      } else {
        siteStatus = true;
      }
      // 尚未加载基地范围
      if (!this.farmAreaReadyMap.get(farm.sourceId)) {
        this.addFarmArea(farm).then(() => {
          areaStatus = true;
          callback();
        });
      } else {
        areaStatus = true;
      }
      // 尚未加载基地处理设备
      if (!this.farmDeviceReadyMap.get(farm.sourceId)) {
        this.addFarmDevice(farm).then(() => {
          deviceStatus = true;
          callback();
        });
      } else {
        deviceStatus = true;
      }
      // 尚未加载基地处理设施
      if (!this.farmDealDeviceReadyMap.get(farm.sourceId)) {
        this.addFarmDealDevice(farm).then(() => {
          dealDeviceStatus = true;
          callback();
        });
      } else {
        dealDeviceStatus = true;
      }
      // 已经加载了该基地设备点
      if (siteStatus && areaStatus) this.flyToFarm(farm);
    },
    // 基地内部点添加地图层级更改事件
    changeByMapZoom(overlay, type) {
      this.map.addEventListener("zoomend", () => {
        let zoom = this.map.getZoom();
        if (zoom > (type || 13)) {
          // if (type === 1) {
          //
          //   if(zoom < 16) {
          //
          //     overlay && overlay.hide()
          //   } else {
          //     overlay && overlay.show()
          //   }
          // } else {
          //   overlay.show();
          // }
          overlay.show()
        }
        else overlay.hide();
      });
    },
    // 添加基地传感器/设备点
    addFarmSites(farm) {
      return new Promise((resolve) => {
        this.$store.dispatch("getFarmSites", farm.sourceId).then((list) => {
          let sites = [];
          for (let i = 0; i < list.length; i++) {
            let item = list[i];
            if (!item.thirdLongLat) continue;
            let [lng, lat] = item.thirdLongLat.split(",");
            let center = new BMapGL.Point(+lng, +lat);
            let nameLabel = new BMapGL.Label(
                `<div style='transform:translateX(-50%);color:#fff;font-size: 16px;letter-spacing: 1px;'>${item.name}</div>
          `,
                {
                  position: center,
                }
            );
            nameLabel.setStyle({
              border: "none",
              background: "none",
            });
            nameLabel.hide();
            this.map.addOverlay(nameLabel);
            if (!item.logo) {
              let marker = new BMapGL.Marker(center);
              marker.hide();
              marker.addEventListener("mouseover", () => nameLabel.show());
              marker.addEventListener("mouseout", () => nameLabel.hide());
              marker.addEventListener("click", () =>
                  this.$emit("site-click", item)
              );
              this.map.addEventListener("zoomend", () => {
                let zoom = this.map.getZoom();
                if (zoom > 13) marker.show();
                else marker.hide();
              });
              this.map.addOverlay(marker);
              sites.push(marker);
            } else {
              let iconUrl = item.servicePath + item.logo;
              let label = new BMapGL.Label(
                  `
            <div style='transform:translate(-50%,-100%)'>
              <img src="${iconUrl}"/>
            </div>
            `,
                  {
                    position: center,
                  }
              );
              label.setStyle({
                border: "none",
                background: "none",
              });
              label.hide();
              label.addEventListener("mouseover", () => nameLabel.show());
              label.addEventListener("mouseout", () => nameLabel.hide());
              this.map.addEventListener("zoomend", () => {
                let zoom = this.map.getZoom();
                if (zoom > 13) label.show();
                else label.hide();
              });
              label.addEventListener("click", () =>
                  this.$emit("site-click", item)
              );
              this.map.addOverlay(label);
              sites.push(label);
            }
          }
          this.farmSitesReadyMap.set(farm.sourceId, sites);
          resolve();
        });
      });
    },
    // 添加基地范围
    addFarmArea(farm) {
      return new Promise((resolve) => {

        this.$store.dispatch("getFarmArea", farm.sourceId).then((list) => {
          let farmAreaReadyMap = [];
          if (farm.coverings && farm.mapCenterLocation) {
            let [lng, lat] = farm.mapCenterLocation.split(",");
            let pngIndex = 1;
            var myIcon = new BMapGL.Icon('/imgs/tgif/' + pngIndex + '.png', new BMapGL.Size(40, 40))
            var pt = new BMapGL.Point(+lng, +lat);
           let marker1 = new BMapGL.Marker(pt, {
              icon: myIcon
            });
            this.map.addOverlay(marker1);
           this.iconInterVal = setInterval(_ => {
              pngIndex++
              if (pngIndex > 25) pngIndex = 1;
              marker1.setIcon(new BMapGL.Icon('/imgs/tgif/' + pngIndex + '.png', new BMapGL.Size(40, 40)))
            },40)
            marker1.hide()
            this.changeByMapZoom(marker1);
          }
          for (let i = 0; i < list.length; i++) {
            let item = list[i];
            let mapPosition = JSON.parse(item.mapPosition);
            for (let j = 0; j < mapPosition.length; j++) {
              let temp = mapPosition[j];
              let center = JSON.parse(temp.options.center);
              if (temp.type == "marker") {
                let point = new BMapGL.Point(center.lng, center.lat);
                let marker = new BMapGL.Marker(point);
                farmAreaReadyMap.push(marker);
                this.map.addOverlay(marker);
                this.changeByMapZoom(marker);
              }
              if (temp.type == "line") {
                let path = JSON.parse(temp.options.path).map(
                    (item) => new BMapGL.Point(item.lng, item.lat)
                );
                let polyline = new BMapGL.Polyline(path, {
                  strokeWeight: temp.options.strokeWeight,
                  strokeColor: temp.options.strokeColor,
                  strokeOpacity: temp.options.strokeOpacity,
                });
                farmAreaReadyMap.push(polyline);
                this.map.addOverlay(polyline);
                this.changeByMapZoom(polyline);
              }
              if (temp.type == "polygon") {
                let path = JSON.parse(temp.options.path).map(
                    (item) => new BMapGL.Point(item.lng, item.lat)
                );
                let polygon = new BMapGL.Polyline(path, {
                  strokeWeight: +temp.options.strokeWeight,
                  strokeColor: temp.options.strokeColor,
                  strokeOpacity: +temp.options.strokeOpacity,
                  fillColor: temp.options.fillColor,
                  fillOpacity: +temp.options.fillOpacity,
                });
                farmAreaReadyMap.push(polygon);
                this.map.addOverlay(polygon);
                this.changeByMapZoom(polygon);
              }
              if (temp.type === "circle") {
                let point = new BMapGL.Point(center.lng, center.lat);
                let circle = new BMapGL.Circle(point, +temp.options.radius, {
                  strokeWeight: +temp.options.strokeWeight,
                  strokeColor: temp.options.strokeColor,
                  strokeOpacity: +temp.options.strokeOpacity,
                  fillColor: temp.options.fillColor,
                  fillOpacity: +temp.options.fillOpacity,
                });
                farmAreaReadyMap.push(circle);
                this.map.addOverlay(circle);
                this.changeByMapZoom(circle);
              }
            }
          }
          this.farmAreaReadyMap.set(farm.sourceId, farmAreaReadyMap);
          resolve();
        });
      });
    },
    // 添加基地处理设备
    addFarmDevice(farm) {
      return new Promise((resolve) => {
        this.$store.dispatch("getFarmDevice", farm.sourceId).then((list) => {
          let farmDeviceReadyList = [];
          for (let i = 0; i < list.length; i++) {
            let temp = list[i];
            let [lon, lat] = temp.thirdLongLat.split(",");
            let img = temp.categoryImg
                ? temp.servicePath + temp.categoryImg
                : "";
            let pt = new BMapGL.Point(+lon, +lat);
            let content = img
                ? `<div class='map-overlay-label-class'><img src="${img}"/></div>`
                : `<div class='map-overlay-label-class'><img src="/imgs/location.png"/></div>`;
            let label = new BMapGL.Label(content, {
              position: pt,
              offset: new BMapGL.Size(-12, -12),
            });
            label.setStyle({
              border: "none",
              background: "none",
            });
            let nameLabel = new BMapGL.Label(
                `
              <div style="transform:translate(-50%,100%);color:#fff">${temp.name}</div>
              `,
                {
                  position: pt,
                }
            );
            nameLabel.hide();
            nameLabel.setStyle({
              border: "none",
              background: "none",
            });
            label.addEventListener("mouseover", () => nameLabel.show());
            label.addEventListener("mouseout", () => nameLabel.hide());
            label.addEventListener("click", () => {
              this.$emit("device-click", temp);
            });
            this.changeByMapZoom(label);
            this.map.addOverlay(nameLabel);
            this.map.addOverlay(label);
            farmDeviceReadyList.push(label);
          }
          this.farmDeviceReadyMap.set(farm.sourceId, farmDeviceReadyList);
          resolve();
        });
      });
    },
    // 添加基地处理设施
    addFarmDealDevice(farm) {
      return new Promise((resolve) => {
        this.$store
            .dispatch("getFarmDealDevice", farm.sourceId)
            .then((list) => {
              let dealDeviceList = [];
              for (let i = 0; i < list.length; i++) {
                let temp = list[i];
                let mapPosition;
                try {
                  mapPosition = JSON.parse(temp.mapPosition);
                } catch {
                  let [lon, lat] = temp.thirdLongLat.split(",");
                  let img = temp.categoryImg
                      ? temp.servicePath + temp.categoryImg
                      : "";
                  let pt = new BMapGL.Point(+lon, +lat);
                  let content = img
                      ? `<div class='map-overlay-label-class'><img src="${img}"/></div>`
                      : `<div class='map-overlay-label-class'><img src="/imgs/location.png"/></div>`;
                  let label = new BMapGL.Label(content, {
                    position: pt,
                    offset: new BMapGL.Size(-12, -12),
                  });
                  label.setStyle({
                    border: "none",
                    background: "none",
                  });
                  let nameLabel = new BMapGL.Label(
                      `<div style="transform:translate(-50%,100%);color:#fff">${temp.categoryName}</div>`,
                      {
                        position: pt,
                      }
                  );
                  nameLabel.hide();
                  nameLabel.setStyle({
                    border: "none",
                    background: "none",
                  });
                  label.addEventListener("mouseover", () => nameLabel.show());
                  label.addEventListener("mouseout", () => nameLabel.hide());
                  this.changeByMapZoom(label);
                  this.map.addOverlay(nameLabel);
                  this.map.addOverlay(label);
                  dealDeviceList.push(label);
                  continue;
                }
                for (let j = 0; j < mapPosition.length; j++) {
                  let tempCover = mapPosition[j];
                  if (tempCover.type == "line") {
                    let path = JSON.parse(tempCover.options.path).map(
                        (item) => new BMapGL.Point(item.lng, item.lat)
                    );
                    let polyline = new BMapGL.Polyline(path, {
                      strokeColor: tempCover.options.strokeColor,
                      strokeWeight: +tempCover.options.strokeWeight,
                      strokeOpacity: +tempCover.options.strokeOpacity,
                    });
                    dealDeviceList.push(polyline);
                    this.map.addOverlay(polyline);
                  }
                }
              }
              this.farmDealDeviceReadyMap.set(farm.sourceId, dealDeviceList);
              resolve();
            });
      });
    },
    // 视角拉下基地
    flyToFarm(farm) {
      let points = [];
      // 基地点
      let sites = this.farmSitesReadyMap.get(farm.sourceId);
      for (let i = 0; i < sites.length; i++) {
        let position = sites[i].getPosition();
        points.push(position);
      }
      // 基地范围
      let areaList = this.farmAreaReadyMap.get(farm.sourceId);
      for (let i = 0; i < areaList.length; i++) {
        let item = areaList[i];
        if (item.getPosition) {
          let position = item.getPosition();
          points.push(position);
        }
        if (item.getCenter) {
          let position = item.getCenter();
          points.push(position);
        }
        if (item.getPath) {
          let path = item.getPath();
          points.push(...path);
        }
      }

      // 处理设备
      let devices = this.farmDeviceReadyMap.get(farm.sourceId);
      for (let i = 0; i < devices.length; i++) {
        let position = devices[i].getPosition();
        points.push(position);
      }

      // 处理设施
      let dealDeviceList = this.farmDealDeviceReadyMap.get(farm.sourceId);
      for (let i = 0; i < dealDeviceList.length; i++) {
        let item = dealDeviceList[i];
        if (item.getPosition) {
          let position = item.getPosition();
          points.push(position);
        }
        if (item.getCenter) {
          let position = item.getCenter();
          points.push(position);
        }
        if (item.getPath) {
          let path = item.getPath();
          points.push(...path);
        }
      }
      if (points.length === 0) return;
      if (points.length === 1) {
        this.map.flyTo(points[0], 15);
        return;
      }
      let bounds = new BMapGL.Bounds(points[0], points[0]);
      if(!this.$route.name ==='produceMap') {
        for (let i = 1; i < points.length; i++) {
          bounds.extend(points[i]);
        }
      }

      this.map.flyTo(bounds.getCenter(),17.5);
    },
    reset() {
      let mapStyle = this.map.getMapType();
      mapStyle !== BMAP_NORMAL_MAP && this.map.setMapType(BMAP_NORMAL_MAP);
      setTimeout(() => {
        this.map.flyTo(new BMapGL.Point(...this.center), this.zoom);
      }, 1 * 1000);
      this.iconInterVal && clearInterval(this.iconInterVal);
    },
  },
  mounted() {
    if (this.farmFilterDefault) this.farmFilter = this.farmFilterDefault;
    this.initMap().then((res) => {
      this.hideOverChina();
      this.boundaryName && this.loadBoundary();
      this.addFarmDot();
    });
    // setTimeout(() => {
    //   console.log(this.map.getCenter());
    //   console.log(this.map.getZoom());
    // }, 20 * 1000);
  },
  watch: {
    // zoom(value) {
    //   if (value) {
    //     this.initMap().then((res) => {
    //       this.boundaryName && this.loadBoundary();
    //       this.addFarmDot();
    //     });
    //   }
    // },
  },
  beforeDestroy() {
    this.iconInterVal && clearInterval(this.iconInterVal)
  }
};
</script>
<style lang='less' scoped>
@import url("./index.less");
</style>
<style lang='less'>
.map-overlay-label-class {
  // transform: translate(-50%, -50%);
  img {
    width: 24px;
    height: 24px;
  }
}
</style>
