<template>
  <div class="indexMap">
    <div v-if="mapLoading" class="loadingMask">
      <div class="loading">
        <loadingSpin
            :style="{
            color: '#ffffff',
            fontSize: '80px',
          }"
        />
        <p>地图加载中...</p>
      </div>
    </div>
    <div id="mapContainer" class="mapContainer"></div>
    <div v-if="imgBoxShow" class="imgBox" @click="() => {imgBoxShow = false}">
      <Icon class="close" type="md-close" @click="() => {imgBoxShow = false}"/>
      <div class="img">

        <img v-if="imgUrl" :src="imgUrl" alt="">
      </div>
    </div>
  </div>
</template>

<script>
import mapboxgl from "mapbox-gl";
import {MAP_PK} from "@/common/constants";
import loadingSpin from "@/components/loading";
import styleJson from "./style.json";
import styleOutdoor from "./style_outdoor.json";
import {
  getFeatureCollectionCenter,
  // getFeatureCollectionBounds,
  // getMapBounds,
  // wgs84tobd09,
} from "@/common/util/mapTools";
import {bd09towgs84, wgs84togcj02} from "../../../common/util/mapTools";

export default {
  name: "index",
  components: {loadingSpin},
  props: {
    center: {
      type: Array,
      default: () => [],

    },
    zoom: {
      type: Number,
      default: () => 10,

    }
  },
  data() {
    return {
      imgBoxShow: false,
      imgUrl: '',
      description: "",
      mapInstance: null,
      mapLoading: false,
      farm: new Set([]),
      animationIcon: null,
      farmData: {}
    }
  },
  created() {
    this.loadImage()
  },
  mounted() {


    this.initMap()
  },
  methods: {
    initMap() {
      // this.mapLoading = true;
      console.log(document.getElementById("mapContainer").clientWidth)

      let mapParams = {
        container: "mapContainer",
        style: styleJson, // MAPBOX_STYLE,
        pitch: 0,
        zoom: this.zoom,
        center: [106.54940292957053, 29.561248875005035],
      };
      mapboxgl.accessToken = MAP_PK;
      this.mapInstance = new mapboxgl.Map(mapParams);
      this.$emit("mapReady", {map: this.mapInstance});
      console.log('初始化地图开始')
      this.mapInstance.on("load", () => {
        console.log('地图加载完成')

      });
      this.mapInstance.once("idle", (e) => {
        console.log('地图加载', e)
        this.mapLoading = false;

      });
      this.mapInstance.on('click', (e) => {
        var features = this.mapInstance.queryRenderedFeatures(e.point, {});
        if (features && features.length > 0) {
          let a = features[0]
          try {
            let d = JSON.parse(a.properties.originData)
            let url = 'https://castoff.hogdata.cn' + d.coverings
            this.imgUrl = url
            this.imgBoxShow = true

            console.log(d);
          } catch (e) {
            console.log(e);
          }
        }
        // console.log(features,e);
      })

    },
    flyToFarm(center, data) {
      this.farmData = data
      // center = wgs84togcj02(center[0],center[1])
      this.mapInstance.flyTo({
        center: center,
        zoom: 15
      })
      if (!this.farm.has("f_" + data.id)) {
        if (!this.mapInstance.getSource('source_' + data.id)) {
          this.mapInstance.addSource('source_' + data.id, {
            'type': 'geojson',
            'data': {
              type: "FeatureCollection",
              features: [
                {
                  type: "Feature",
                  properties: {
                    icon: "img-0",
                    originData: {
                      ...data
                    }
                  },
                  geometry: {
                    type: "Point",
                    coordinates: center//此处设置图标的经纬度坐标,确定图标在地图上显示的位置
                  },
                },
              ],
            },
          });
        }
        if (!this.mapInstance.getLayer("l_" + data.id)) {
          this.mapInstance.addLayer({
            id: "l_" + data.id,
            type: "symbol",
            source: "source_" + data.id,
            layout: {
              "icon-image": ["get", "icon"],
              "icon-size": 1,//图标的大小
            },
            paint: {
              "text-halo-color": "rgb(255,255,255)",
              "text-halo-width": 2,
            },
          });
          this.farm.add("f_" + data.id)
        }
        console.log(this.mapInstance.getSource('point'))

      }
      let map = this.mapInstance
      let i = 0;
      //当前执行时间
      let nowTime = 0;
      //let记录每次动画执行结束的时间
      let lastTime = Date.now();
      //我们自己定义的动画时间差值
      let diffTime = 60;
      let that = this
      if (that.animationIcon) {
        cancelAnimationFrame(that.animationIcon)
      }

      function animateMarker(timestamp) {
        // Update the data to a new position based on the animation timestamp. The
        // divisor in the expression `timestamp / 1000` controls the animation speed.
        // map.getSource('point')  /* getSource(id)获取id的source，setData()设置source的数据*/
        //记录当前时间

        nowTime = Date.now()
        // console.log(nowTime)
        // 当前时间-上次执行时间如果大于diffTime，那么执行动画，并更新上次执行时间
        if (nowTime - lastTime > diffTime) {
          lastTime = nowTime
          i++
          if (!map.hasImage("img-" + i)) {
            i = 0
          }
          map.getSource('source_' + data.id)?.setData({
            type: "FeatureCollection",
            features: [
              {
                type: "Feature",
                properties: {
                  icon: "img-" + i,
                  originData: {
                    ...data
                  }
                },
                geometry: {
                  type: "Point",
                  coordinates: center//此处设置图标的经纬度坐标,确定图标在地图上显示的位置
                },
              },
            ],

          })
        }


        // Request the next frame of the animation.
        that.animationIcon = requestAnimationFrame(animateMarker); /* requestAnimationFrame() 可以实现动画效果，本例即使典型用法，当然也可以返回一个句柄，必要时取消动画 */
      }

      // Start the animation，执行函数实现动画
      animateMarker(0);
    },
    loadImage() {
      let arr = []
      for (let i = 1; i < 26; i++) {
        arr.push(loadImgae(i))
      }
      Promise.all(arr).then(r => {
        r.map((re, index) => {
          this.mapInstance.addImage("img-" + index, re)
        })
        console.log(`序列图加载完毕共计${r.length}张`)
      })

      function loadImgae(num = 1) {
        return new Promise((resolve, reject) => {
          const image = new Image();
          image.src = "/imgs/tgif/" + num + '.png'
          image.onload = () => {
            resolve(image);
          };
        })
      }
    },
    reset() {
      this.mapInstance.flyTo({
        center: this.center,
        zoom: this.zoom
      })
      if (this.animationIcon) {
        cancelAnimationFrame(this.animationIcon)
      }
      try {
        setTimeout(_ => {
          this.mapInstance.removeLayer('l_' + this.farmData.id)
          this.mapInstance.removeSource('source_' + this.farmData.id)
          this.farm.delete('f_' + this.farmData.id)
        }, 500)
      } catch (e) {
        console.log(e);
      }
      // this.mapInstance.removeLayers()
    }
  },
  beforeDestroy() {
    this.animationIcon && cancelAnimationFrame(this.animationIcon)
  }
}
</script>

<style lang="less" scoped>
.indexMap {
  width: 100%;
  height: 100%;

  .loadingMask {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: calc(~"100vh - 88px");
    background-color: rgba(0, 0, 0, 0.4);
    z-index: 9;

    .loading {
      font-size: 18px;
      color: #ffffff;
      text-align: center;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }

  .mapContainer {
    //width: 100%;
    height: 100%;

  }
}

.imgBox {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.75);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100000;

  .close {
    position: absolute;
    top: 0;
    right: 0;
    color: white;
    cursor: pointer;
    font-size: 22px;
    width: 50px;
    height: 50px;
    background-color: #000;
    border-bottom-left-radius: 100%;
    padding: 8px 0 0 20px;
  }

  .img {
    width: 500px;
    height: 350px;
    position: relative;


    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}
</style>
<style lang="less">
.vPhoto {
  .viewer-canvas {
    img {
      width: 500px;
      height: 400px;
    }
  }
}
</style>
