<template>
  <div class="map-component-container">
    <div ref="map" class="map-component"></div>
  </div>
</template>
<script>
import { mapState } from "vuex";
// import BMapGroundOverlay from "@/assets/js/BMapGroundOverlay";

export default {
  props: {
    stopDefaultFarmClick: {
      type: Boolean,
      default: false,
    },
    center: {
      type: Array,
      default() {
        return [105.52314042411466, 29.475651579673745];
        // return [117.19635, 36.24093];
      },
    },
    zoom: {
      type: Number,
      default: 11,
      // default: 5,
    },
    // 加载区域范围
    boundaryName: {
      type: String,
      default: "重庆市荣昌区",
    },
    farmFilterDefault: Set,
  },
  data() {
    return {
      map: null,
      farmFilter: new Set(),
      // 地图上基地点集合
      farmDotMap: new Map(),
      // 已经加载了设备点的基地集合
      farmSitesReadyMap: new Map(),
      // 已经加载了基地方位的基地集合
      farmAreaReadyMap: new Map(),
      // 已经加载了处理设备的基地结合
      farmDeviceReadyMap: new Map(),
      // 已经加载了处理设施的基地结合
      farmDealDeviceReadyMap: new Map(),
    };
  },
  computed: {
    ...mapState({
      farmReady: (state) => state.map.farmReady,
      farmList: (state) => state.map.farmList,
    }),
  },
  methods: {
    setFilter(filter) {
      this.farmFilter = filter;
      let zoom = this.map.getZoom();
      if (zoom > 13) return;
      for (let i = 0; i < this.farmList.length; i++) {
        let farm = this.farmList[i];
        if (filter && filter.size > 0 && !filter.has(farm.siteType)) {
          this.farmDotMap.get(farm.sourceId) &&
            this.farmDotMap.get(farm.sourceId).hide();
        } else {
          this.farmDotMap.get(farm.sourceId) &&
            this.farmDotMap.get(farm.sourceId).show();
        }
      }
    },
    //   初始化地图
    initMap() {
      return new Promise((resolve) => {
        this.map = new BMapGL.Map(this.$refs.map, {
          mapType: BMAP_EARTH_MAP,
        });
        this.map.setMapStyleV2({
          styleId: "71fdecf2027b3267d7d2bef59b447b6a",
        });

        this.map.centerAndZoom(new BMapGL.Point(...this.center), this.zoom);
        this.map.enableScrollWheelZoom(true);
        resolve();
      });
    },
    // 加载区域范围
    loadBoundary() {
      if (!this.boundaryName) return;
      let boundary = new BMapGL.Boundary();
      boundary.get(this.boundaryName, (e) => {
        console.log(e);
        if (!e) return;
        if (!e.boundaries || e.boundaries.length === 0) return;
        // for (let i = 0; i < e.boundaries.length; i++) {
        let point = e.boundaries[0];
        let points = point.split(";").map((item) => {
          let it = item.split(",");
          return new BMapGL.Point(it[0], it[1]);
        });
        let polygon = new BMapGL.Polygon(points, {
          strokeColor: "#08dddd",
          fillColor: "#08dddd",
          strokeWeight: 2,
          strokeOpacity: 1,
          fillOpacity: 0.3,
          strokeStyle: "solid",
        });
        this.map.addOverlay(polygon);
        // }
      });
    },
    // 加载所有基地点
    addFarmDot() {
      if (!this.farmReady) {
        this.$store.dispatch("getAllFarms").then((list) => {
          this.addFarmDotDo(list);
        });
      } else {
        this.addFarmDotDo(this.farmList);
      }
    },
    addFarmDotDo(list) {
      for (let i = 0; i < list.length; i++) {
        let item = list[i];
        if (!item.mapCenterLocation) continue;
        let [lng, lat] = item.mapCenterLocation.split(",");
        // 加载图标
        let icon = new BMapGL.Icon(
          `/imgs/map/sitetype${item.siteType}.png`,
          new BMapGL.Size(24, 24),
          {
            anchor: new BMapGL.Size(12, 24),
          }
        );
        let point = new BMapGL.Point(+lng, +lat);
        let marker = new BMapGL.Marker(point, {
          icon,
        });
        // 加载名称
        let label = new BMapGL.Label(
          `<div style='transform:translateX(-50%);color:#fff;font-size: 16px;letter-spacing: 1px;'>${item.mapName}</div>
          `,
          {
            position: point,
            offset: new BMapGL.Size(0, 4),
          }
        );
        label.setStyle({
          border: "none",
          background: "none",
        });
        label.hide();
        this.map.addOverlay(label);
        marker.addEventListener("mouseover", () => label.show());
        marker.addEventListener("mouseout", () => label.hide());
        marker.addEventListener("click", () => {
          this.farmClick(item);
        });
        if (
          this.farmFilter &&
          this.farmFilter.size > 0 &&
          !this.farmFilter.has(item.siteType)
        )
          marker.hide();
        this.map.addOverlay(marker);
        this.farmDotMap.set(item.sourceId, marker);
        this.map.addEventListener("zoomend", () => {
          let zoom = this.map.getZoom();
          if (zoom > 13) marker.hide();
          else if (
            this.farmFilter &&
            this.farmFilter.size > 0 &&
            !this.farmFilter.has(item.siteType)
          )
            marker.hide();
          else marker.show();
        });
      }
    },
    // 基地点击
    farmClick(farm, stopPop = false) {
      if (!stopPop) this.$emit("farm-click", farm);
      if (this.stopDefaultFarmClick) return;
      // 基地点状态
      let siteStatus = false,
        // 基地范围状态
        areaStatus = false,
        // 处理设备状态
        deviceStatus = false,
        // 处理设施状态
        dealDeviceStatus = false;
      let callback = () => {
        if (siteStatus && areaStatus && deviceStatus && dealDeviceStatus) {
          this.flyToFarm(farm);
        }
      };
      // 尚未加载该基地设备点
      if (!this.farmSitesReadyMap.get(farm.sourceId)) {
        // 添加基地传感器/设备点
        this.addFarmSites(farm).then(() => {
          siteStatus = true;
          callback();
        });
      } else {
        siteStatus = true;
      }
      // 尚未加载基地范围
      if (!this.farmAreaReadyMap.get(farm.sourceId)) {
        this.addFarmArea(farm).then(() => {
          areaStatus = true;
          callback();
        });
      } else {
        areaStatus = true;
      }
      // 尚未加载基地处理设备
      if (!this.farmDeviceReadyMap.get(farm.sourceId)) {
        this.addFarmDevice(farm).then(() => {
          deviceStatus = true;
          callback();
        });
      } else {
        deviceStatus = true;
      }
      // 尚未加载基地处理设施
      if (!this.farmDealDeviceReadyMap.get(farm.sourceId)) {
        this.addFarmDealDevice(farm).then(() => {
          dealDeviceStatus = true;
          callback();
        });
      } else {
        dealDeviceStatus = true;
      }
      // 已经加载了该基地设备点
      if (siteStatus && areaStatus) this.flyToFarm(farm);
    },
    // 基地内部点添加地图层级更改事件
    changeByMapZoom(overlay) {
      this.map.addEventListener("zoomend", () => {
        let zoom = this.map.getZoom();
        if (zoom > 13) overlay.show();
        else overlay.hide();
      });
    },
    // 添加基地传感器/设备点
    addFarmSites(farm) {
      return new Promise((resolve) => {
        this.$store.dispatch("getFarmSites", farm.sourceId).then((list) => {
          let sites = [];
          for (let i = 0; i < list.length; i++) {
            let item = list[i];
            if (!item.thirdLongLat) continue;
            let [lng, lat] = item.thirdLongLat.split(",");
            let center = new BMapGL.Point(+lng, +lat);
            let nameLabel = new BMapGL.Label(
              `<div style='transform:translateX(-50%);color:#fff;font-size: 16px;letter-spacing: 1px;'>${item.name}</div>
          `,
              {
                position: center,
              }
            );
            nameLabel.setStyle({
              border: "none",
              background: "none",
            });
            nameLabel.hide();
            this.map.addOverlay(nameLabel);
            if (!item.logo) {
              let marker = new BMapGL.Marker(center);
              marker.hide();
              marker.addEventListener("mouseover", () => nameLabel.show());
              marker.addEventListener("mouseout", () => nameLabel.hide());
              marker.addEventListener("click", () =>
                this.$emit("site-click", item)
              );
              this.map.addEventListener("zoomend", () => {
                let zoom = this.map.getZoom();
                if (zoom > 13) marker.show();
                else marker.hide();
              });
              this.map.addOverlay(marker);
              sites.push(marker);
            } else {
              let iconUrl = item.servicePath + item.logo;
              let label = new BMapGL.Label(
                `
            <div style='transform:translate(-50%,-100%)'>
              <img src="${iconUrl}"/>
            </div>
            `,
                {
                  position: center,
                }
              );
              label.setStyle({
                border: "none",
                background: "none",
              });
              label.hide();
              label.addEventListener("mouseover", () => nameLabel.show());
              label.addEventListener("mouseout", () => nameLabel.hide());
              this.map.addEventListener("zoomend", () => {
                let zoom = this.map.getZoom();
                if (zoom > 13) label.show();
                else label.hide();
              });
              label.addEventListener("click", () =>
                this.$emit("site-click", item)
              );
              this.map.addOverlay(label);
              sites.push(label);
            }
          }
          this.farmSitesReadyMap.set(farm.sourceId, sites);
          resolve();
        });
      });
    },
    // 添加基地范围
    addFarmArea(farm) {
      return new Promise((resolve) => {
        this.$store.dispatch("getFarmArea", farm.sourceId).then((list) => {
          let farmAreaReadyMap = [];
          for (let i = 0; i < list.length; i++) {
            let item = list[i];
            let mapPosition = JSON.parse(item.mapPosition);
            for (let j = 0; j < mapPosition.length; j++) {
              let temp = mapPosition[j];
              let center = JSON.parse(temp.options.center);
              if (temp.type == "marker") {
                let point = new BMapGL.Point(center.lng, center.lat);
                let marker = new BMapGL.Marker(point);
                farmAreaReadyMap.push(marker);
                this.map.addOverlay(marker);
                this.changeByMapZoom(marker);
              }
              if (temp.type == "line") {
                let path = JSON.parse(temp.options.path).map(
                  (item) => new BMapGL.Point(item.lng, item.lat)
                );
                let polyline = new BMapGL.Polyline(path, {
                  strokeWeight: temp.options.strokeWeight,
                  strokeColor: temp.options.strokeColor,
                  strokeOpacity: temp.options.strokeOpacity,
                });
                farmAreaReadyMap.push(polyline);
                this.map.addOverlay(polyline);
                this.changeByMapZoom(polyline);
              }
              if (temp.type == "polygon") {
                let path = JSON.parse(temp.options.path).map(
                  (item) => new BMapGL.Point(item.lng, item.lat)
                );
                let polygon = new BMapGL.Polyline(path, {
                  strokeWeight: +temp.options.strokeWeight,
                  strokeColor: temp.options.strokeColor,
                  strokeOpacity: +temp.options.strokeOpacity,
                  fillColor: temp.options.fillColor,
                  fillOpacity: +temp.options.fillOpacity,
                });
                farmAreaReadyMap.push(polygon);
                this.map.addOverlay(polygon);
                this.changeByMapZoom(polygon);
              }
              if (temp.type === "circle") {
                let point = new BMapGL.Point(center.lng, center.lat);
                let circle = new BMapGL.Circle(point, +temp.options.radius, {
                  strokeWeight: +temp.options.strokeWeight,
                  strokeColor: temp.options.strokeColor,
                  strokeOpacity: +temp.options.strokeOpacity,
                  fillColor: temp.options.fillColor,
                  fillOpacity: +temp.options.fillOpacity,
                });
                farmAreaReadyMap.push(circle);
                this.map.addOverlay(circle);
                this.changeByMapZoom(circle);
              }
            }
          }
          this.farmAreaReadyMap.set(farm.sourceId, farmAreaReadyMap);
          resolve();
        });
      });
    },
    // 添加基地处理设备
    addFarmDevice(farm) {
      return new Promise((resolve) => {
        this.$store.dispatch("getFarmDevice", farm.sourceId).then((list) => {
          let farmDeviceReadyList = [];
          for (let i = 0; i < list.length; i++) {
            let temp = list[i];
            let [lon, lat] = temp.thirdLongLat.split(",");
            let img = temp.categoryImg
              ? temp.servicePath + temp.categoryImg
              : "";
            let pt = new BMapGL.Point(+lon, +lat);
            let content = img
              ? `<div class='map-overlay-label-class'><img src="${img}"/></div>`
              : `<div class='map-overlay-label-class'><img src="/imgs/location.png"/></div>`;
            let label = new BMapGL.Label(content, {
              position: pt,
              offset: new BMapGL.Size(-12, -12),
            });
            label.setStyle({
              border: "none",
              background: "none",
            });
            let nameLabel = new BMapGL.Label(
              `
              <div style="transform:translate(-50%,100%);color:#fff">${temp.name}</div>
              `,
              {
                position: pt,
              }
            );
            nameLabel.hide();
            nameLabel.setStyle({
              border: "none",
              background: "none",
            });
            label.addEventListener("mouseover", () => nameLabel.show());
            label.addEventListener("mouseout", () => nameLabel.hide());
            label.addEventListener("click", () => {
              this.$emit("device-click", temp);
            });
            this.changeByMapZoom(label);
            this.map.addOverlay(nameLabel);
            this.map.addOverlay(label);
            farmDeviceReadyList.push(label);
          }
          this.farmDeviceReadyMap.set(farm.sourceId, farmDeviceReadyList);
          resolve();
        });
      });
    },
    // 添加基地处理设施
    addFarmDealDevice(farm) {
      return new Promise((resolve) => {
        this.$store
          .dispatch("getFarmDealDevice", farm.sourceId)
          .then((list) => {
            let dealDeviceList = [];
            for (let i = 0; i < list.length; i++) {
              let temp = list[i];
              let mapPosition;
              try {
                mapPosition = JSON.parse(temp.mapPosition);
              } catch {
                let [lon, lat] = temp.thirdLongLat.split(",");
                let img = temp.categoryImg
                  ? temp.servicePath + temp.categoryImg
                  : "";
                let pt = new BMapGL.Point(+lon, +lat);
                let content = img
                  ? `<div class='map-overlay-label-class'><img src="${img}"/></div>`
                  : `<div class='map-overlay-label-class'><img src="/imgs/location.png"/></div>`;
                let label = new BMapGL.Label(content, {
                  position: pt,
                  offset: new BMapGL.Size(-12, -12),
                });
                label.setStyle({
                  border: "none",
                  background: "none",
                });
                let nameLabel = new BMapGL.Label(
                  `<div style="transform:translate(-50%,100%);color:#fff">${temp.categoryName}</div>`,
                  {
                    position: pt,
                  }
                );
                nameLabel.hide();
                nameLabel.setStyle({
                  border: "none",
                  background: "none",
                });
                label.addEventListener("mouseover", () => nameLabel.show());
                label.addEventListener("mouseout", () => nameLabel.hide());
                this.changeByMapZoom(label);
                this.map.addOverlay(nameLabel);
                this.map.addOverlay(label);
                dealDeviceList.push(label);
                continue;
              }
              for (let j = 0; j < mapPosition.length; j++) {
                let tempCover = mapPosition[j];
                if (tempCover.type == "line") {
                  let path = JSON.parse(tempCover.options.path).map(
                    (item) => new BMapGL.Point(item.lng, item.lat)
                  );
                  let polyline = new BMapGL.Polyline(path, {
                    strokeColor: tempCover.options.strokeColor,
                    strokeWeight: +tempCover.options.strokeWeight,
                    strokeOpacity: +tempCover.options.strokeOpacity,
                  });
                  dealDeviceList.push(polyline);
                  this.map.addOverlay(polyline);
                }
              }
            }
            this.farmDealDeviceReadyMap.set(farm.sourceId, dealDeviceList);
            resolve();
          });
      });
    },
    // 视角拉下基地
    flyToFarm(farm) {
      let points = [];
      // 基地点
      let sites = this.farmSitesReadyMap.get(farm.sourceId);
      for (let i = 0; i < sites.length; i++) {
        let position = sites[i].getPosition();
        points.push(position);
      }
      // 基地范围
      let areaList = this.farmAreaReadyMap.get(farm.sourceId);
      for (let i = 0; i < areaList.length; i++) {
        let item = areaList[i];
        if (item.getPosition) {
          let position = item.getPosition();
          points.push(position);
        }
        if (item.getCenter) {
          let position = item.getCenter();
          points.push(position);
        }
        if (item.getPath) {
          let path = item.getPath();
          points.push(...path);
        }
      }

      // 处理设备
      let devices = this.farmDeviceReadyMap.get(farm.sourceId);
      for (let i = 0; i < devices.length; i++) {
        let position = devices[i].getPosition();
        points.push(position);
      }

      // 处理设施
      let dealDeviceList = this.farmDealDeviceReadyMap.get(farm.sourceId);
      for (let i = 0; i < dealDeviceList.length; i++) {
        let item = dealDeviceList[i];
        if (item.getPosition) {
          let position = item.getPosition();
          points.push(position);
        }
        if (item.getCenter) {
          let position = item.getCenter();
          points.push(position);
        }
        if (item.getPath) {
          let path = item.getPath();
          points.push(...path);
        }
      }

      if (points.length === 0) return;
      if (points.length === 1) {
        this.map.flyTo(points[0], 15);
        return;
      }
      let bounds = new BMapGL.Bounds(points[0], points[0]);
      for (let i = 1; i < points.length; i++) {
        bounds.extend(points[i]);
      }
      this.map.flyTo(bounds.getCenter(), 17.5);
    },
    reset() {
      this.map.reset();
    },
  },
  mounted() {
    if (this.farmFilterDefault) this.farmFilter = this.farmFilterDefault;
    this.initMap().then((res) => {
      this.boundaryName && this.loadBoundary();
      this.addFarmDot();
    });
    setTimeout(() => {
      console.log(this.map.getCenter());
      console.log(this.map.getZoom());
    }, 20 * 1000);
  },
  watch: {
    zoom(value) {
      if (value) {
        this.initMap().then((res) => {
          this.boundaryName && this.loadBoundary();
          this.addFarmDot();
        });
      }
    },
  },
};
</script>
<style lang='less' scoped>
@import url("./index.less");
</style>
<style lang='less'>
.map-overlay-label-class {
  // transform: translate(-50%, -50%);
  img {
    width: 24px;
    height: 24px;
  }
}
</style>
