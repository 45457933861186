<template>
  <div>
    <template v-if="!loading">
      <company-info :content="content" v-if="content"></company-info>
      <farm-view v-else :currentMapInfo="currentFarm"></farm-view>
    </template>
  </div>
</template>
<script>
import companyInfo from "./companyInfo.vue";
import farmView from "./farmView.vue";
export default {
  components: {
    companyInfo,
    farmView,
  },
  props: {
    currentFarm: Object,
  },
  data() {
    return {
      loading: true,
      content: "",
    };
  },
  watch: {
    currentFarm: {
      handler() {
        this.getDetail();
      },
      deep: true,
    },
  },
  methods: {
    // 获取详情
    getDetail() {
      if (!this.currentFarm) return;
      this.loading = true;
      this.$post(this.$api.BASE_MANAGE.VIEW, {
        id: this.currentFarm.sourceId,
      })
        .then((res) => {
          this.content = res.remark;
        })
        .catch(() => {
          this.content = "";
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
  mounted() {
    this.getDetail();
  },
};
</script>
<style lang='less' scoped>
@import url("./index.less");
</style>