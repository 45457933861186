<template>
  <surround-box
    :title="menuName"
    :width="440"
    background="rgba(1, 12, 10, 0.8)"
  >
    <div class="land-carry-capacity-model">
      <div class="model-item">
        <div class="model-data">
          <img src="imgs/produceModel/czl.png" alt="" />
          <div class="data-box">
            <p class="num">0.623</p>
            <p>土地承载力</p>
          </div>
          <div class="result-box">
            <span class="symbol">≈</span>
            <span class="pig-num">当季每亩地0.62头猪</span>
          </div>
        </div>
        <div class="model-title">
          <span>土地承载力计算模型</span>
          <span class="iconfont icon">&#xe6e4;</span>
        </div>
        <div class="model-formula">
          <div class="formula-item">
            <span class="result">T</span>
            <span>=</span>
            <div class="division">
              <p>∈</p>
              <p class="line"></p>
              <p>∏</p>
            </div>
          </div>
        </div>
        <div class="formula-info">
          <p>T：土地承载力（<span>0.8526</span>）</p>
          <p>∈：区域植物粪肥养分需求量（<span>5.9682</span>）</p>
          <p>∏：单位猪当量粪肥养分供给量（<span>7.0</span>）</p>
        </div>
        <div class="demand-formula">
          <div class="demand-formula-item">
            <span>区域植物养分需求量</span>
            <span>=</span>
            <div>
              <span>∑{每种植物总产量(总面积)}</span>
              <span>×</span>
              <span>单位产量{单位面积养分需求}</span>
            </div>
          </div>
          <div class="demand-formula-item">
            <span>区域植物粪肥养分需求量</span>
            <span>=</span>
            <div class="division">
              <p>区域植物养分需求量×施肥养分供给占比×施肥占比</p>
              <p class="line"></p>
              <p>粪肥当季理应率</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </surround-box>
</template>

<script>
import surroundBox from "@/components/mapComp/surroundBox2";
export default {
  name: "",
  components: {
    surroundBox,
  },
  props: {
    menuName: {
      type: String,
      default: "",
    },
    farmId: [String, Number],
  },
  data() {
    return {
      info: {},
    };
  },
  methods: {
    // 获取数据
    getInfo() {
      if (!this.farmId) return;
      this.$post(this.$api.SOIL_CARRYING_CAPACITY_MODEL.CARRYING_CAPACITY, {
        farmId: this.farmId,
      }).then((res) => {
        this.info = res.length > 0 ? res[0] : {};
      });
    },
  },
  mounted() {
    this.getInfo();
  },
};
</script>

<style lang="less" scoped>
@import url("./index.less");
</style>