<template>
  <surround-box :title="menuName">
    <div class="device-account">设施总数量：{{ devices.length }}</div>
    <div class="inner-content-page">
      <div
        @click="checkDeviceInfo(device)"
        class="device-item"
        v-for="device in devices"
        :key="device.id"
      >
        <div class="device-img">
          <img
            :src="
              device.img
                ? device.servicePath + device.img
                : device.categoryImg
                ? device.servicePath + device.categoryImg
                : '/imgs/empty/emptyimg.png'
            "
          />
        </div>
        <div class="device-status">
          <span
            :class="{
              'device-status-dot': true,
              'rest-device': device.status === 0,
              'working-device': device.status === 1,
              'disabled-device': device.status === 3,
            }"
          ></span>
          {{
            device.status === 0
              ? "闲置中"
              : device.status === 1
              ? "工作中"
              : "已废弃"
          }}
        </div>
        <div class="device-name">
          {{ device.categoryName }}
        </div>
      </div>
    </div>
    <Modal v-model="modalData.show" footer-hide>
      <div v-if="modalData.data" class="device-info-form">
        <Row style="padding: 8px 0">
          <Col style="text-align: right" span="5"> 设施名称： </Col>
          <Col span="19">
            {{ modalData.data.categoryName }}
          </Col>
        </Row>
        <Row style="padding: 8px 0">
          <Col style="text-align: right" span="5"> 设施状态： </Col>
          <Col span="19">
            {{
              modalData.data.status === 0
                ? "闲置中"
                : modalData.data.status === 1
                ? "工作中"
                : "已废弃"
            }}
          </Col>
        </Row>
        <Row style="padding: 8px 0">
          <Col style="text-align: right" span="5"> 规格： </Col>
          <Col span="19">
            {{
              modalData.data.size.type == 1
                ? `长度${modalData.data.size.value.length}米，直径${modalData.data.size.value.diameter}米`
                : `长${modalData.data.size.value.long}米，宽${modalData.data.size.value.width}米，高${modalData.data.size.value.high}米`
            }}
          </Col>
        </Row>
        <Row
          v-if="modalData.data.img && modalData.data.img.length > 0"
          style="padding: 8px 0"
        >
          <Col style="text-align: right" span="5"> 设施图片： </Col>
          <Col span="19">
            <img
              style="width: 200px"
              :src="modalData.data.servicePath + img"
              v-for="img in modalData.data.img"
              :key="img"
            />
          </Col>
        </Row>
        <Row
          v-if="modalData.data.drawings && modalData.data.drawings.length > 0"
          style="padding: 8px 0"
        >
          <Col style="text-align: right" span="5"> 设施图纸： </Col>
          <Col span="19">
            <img
              style="width: 200px"
              :src="modalData.data.servicePath + img"
              v-for="img in modalData.data.drawings"
              :key="img"
            />
          </Col>
        </Row>
      </div>
    </Modal>
  </surround-box>
</template>

<script>
import surroundBox from "@/components/mapComp/surroundBox2";
export default {
  components: {
    surroundBox,
  },
  props: {
    menuName: String,
    currentFarm: Object,
  },
  data() {
    return {
      devices: [],
      modalData: {
        show: false,
        data: null,
      },
    };
  },
  methods: {
    // 获取基地设施列表
    getDevices() {
      this.$post(this.$api.DEAL_FACILITIES.LIST, {
        pageNo: 1,
        pageSize: 9999,
        siteType: "2",
        farmId: this.currentFarm.sourceId,
      }).then((res) => {
        this.devices = res.list;
      });
    },
    // 查看设施详情
    checkDeviceInfo(device) {
      this.modalData.data = {
        ...device,
        size: JSON.parse(device.size),
        img: device.img ? device.img.split(",") : [],
        drawings: device.drawings ? device.drawings.split(",") : [],
      };
      this.modalData.show = true;
    },
  },
  mounted() {
    this.getDevices();
  },
};
</script>

<style lang="less" scoped>
@import url("./index.less");
</style>