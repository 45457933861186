<template>
  <div class="legend-pane background-colors">
    <div v-for="(item, index) in legends" :key="index" class="legends-box">
      <img src="/imgs/map/sitetype2.png" alt="" />
      <span class="legend-name">{{ item.name }}</span>
      <span class="legend-num">{{ item.num }} 个</span>
    </div>
    <div class="left-top-corner"></div>
    <div class="right-top-corner"></div>
    <div class="right-bottom-corner"></div>
    <div class="left-bottom-corner"></div>
  </div>
</template>

<script>
export default {
  props: {
    currentFarm: Object,
  },
  watch: {
    currentFarm(n) {
      this.getLegends();
    },
  },
  data() {
    return {
      legends: [],
    };
  },
  methods: {
    getLegends() {
      this.$post(this.$api.LEGEND.DEVICE_LEGEND, {
        siteType: "2",
        farmId: this.currentFarm ? this.currentFarm.sourceId : "",
      }).then((res) => {
        this.legends = res;
      });
    },
  },
  mounted() {
    this.getLegends();
  },
};
</script>

<style lang="less" scoped>
@import url("./index.less");
.legend-pane {
  width: 100%;
  max-height: 200px;
  padding: 16px;
  overflow: auto;
  // background: rgba(23, 34, 60, 0.6);
  border-radius: 4px;
  &::-webkit-scrollbar {
    display: none;
  }
  .legends-box {
    display: flex;
    align-items: center;
    color: #fff;
    &:not(:last-child) {
      padding-bottom: 10px;
    }
    img {
      width: 24px;
      height: 24px;
      flex-shrink: 0;
      flex-grow: 0;
    }
    .legend-name {
      padding-left: 10px;
      flex-shrink: 0;
      flex-grow: 0;
    }
    .legend-num {
      flex-shrink: 1;
      flex-grow: 1;
      text-align: right;
    }
  }
}
</style>