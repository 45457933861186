<template>
  <surround-box>
    <div class="menu-container">
      <div class="title">物联网</div>
      <div class="menu-content">
        <div class="menu-title">
          <img class="title-icon" src="/imgs/halo.png" />
          <span>基础信息</span>
        </div>
        <div class="menu-list">
          <div
            @click="menuChange('baseInfo')"
            :class="['menu-item', current == 'baseInfo' ? 'current-item' : '']"
          >
            基础信息
          </div>
          <div
            @click="menuChange('WeatherPrewarning')"
            :class="[
              'menu-item',
              current == 'WeatherPrewarning' ? 'current-item' : '',
            ]"
          >
            气象预报
          </div>
          <div
            @click="menuChange('DeviceManage')"
            :class="[
              'menu-item',
              current == 'DeviceManage' ? 'current-item' : '',
            ]"
          >
            处理设备
          </div>
          <div
            @click="menuChange('Facilities')"
            :class="[
              'menu-item',
              current == 'Facilities' ? 'current-item' : '',
            ]"
          >
            处理设施
          </div>
          <div
            v-if="type == 3"
            @click="menuChange('ShouNa')"
            :class="['menu-item', current == 'ShouNa' ? 'current-item' : '']"
          >
            粪污收纳
          </div>
          <div
            v-if="type == 3"
            @click="menuChange('JieGan')"
            :class="['menu-item', current == 'JieGan' ? 'current-item' : '']"
          >
            秸秆收纳
          </div>
        </div>
        <div class="menu-title">
          <img class="title-icon" src="/imgs/halo.png" />
          <span>数据采集</span>
        </div>
        <div class="menu-list">
          <div
            v-if="type == 2"
            @click="menuChange('feces')"
            :class="['menu-item', current == 'feces' ? 'current-item' : '']"
          >
            粪污生产
          </div>
          <div
            @click="menuChange('soil')"
            :class="['menu-item', current == 'soil' ? 'current-item' : '']"
          >
            土壤墒情
          </div>
          <div
            v-if="type != 3"
            @click="menuChange('water')"
            :class="['menu-item', current == 'water' ? 'current-item' : '']"
          >
            水体质量
          </div>
          <div
            @click="menuChange('videoMonitor')"
            :class="[
              'menu-item',
              current == 'videoMonitor' ? 'current-item' : '',
            ]"
          >
            视频监控
          </div>
          <div
            @click="menuChange('statistics')"
            :class="[
              'menu-item',
              current == 'statistics' ? 'current-item' : '',
            ]"
          >
            数据统计
          </div>
          <div
            @click="menuChange('ZhuaShou')"
            :class="['menu-item', current == 'ZhuaShou' ? 'current-item' : '']"
            style="width: 68px"
            v-if="router == 'useMap'"
          >
            抓手
          </div>
          <div
            @click="menuChange('BaoZhuang')"
            :class="['menu-item', current == 'BaoZhuang' ? 'current-item' : '']"
            style="width: 68px"
            v-if="router == 'useMap'"
          >
            包装
          </div>
        </div>
        <div class="menu-title">
          <img class="title-icon" src="/imgs/halo.png" />
          <span>数据模型</span>
        </div>
        <div class="menu-list">
          <div
            v-if="type == 2"
            @click="menuChange('productModel')"
            :class="[
              'menu-item',
              current == 'productModel' ? 'current-item' : '',
            ]"
          >
            生产模型
          </div>
          <div
            v-if="type == 2"
            @click="menuChange('sewageModel')"
            :class="[
              'menu-item',
              current == 'sewageModel' ? 'current-item' : '',
            ]"
          >
            污水排放
          </div>
          <div
            @click="menuChange('landCarryCapacityModel')"
            :class="[
              'menu-item',
              current == 'landCarryCapacityModel' ? 'current-item' : '',
            ]"
          >
            土地承载力
          </div>
        </div>
        <div class="menu-title">
          <img class="title-icon" src="/imgs/halo.png" />
          <span>分析预警</span>
        </div>
        <div class="menu-list">
          <div
            @click="menuChange('equipmentWarn')"
            :class="[
              'menu-item',
              current == 'equipmentWarn' ? 'current-item' : '',
            ]"
          >
            设备预警
          </div>
        </div>
      </div>
    </div>
  </surround-box>
</template>
<script>
import surroundBox from "@/components/mapComp/surroundBox";

export default {
  components: {
    surroundBox,
  },
  data() {
    return {
      router: "",
    };
  },
  props: {
    current: {
      type: String,
      default: "baseInfo",
    },
    type: [String, Number],
  },
  methods: {
    menuChange(type) {
      this.$emit("on-change", type);
    },
  },
  mounted() {
    this.menuChange(this.current);
    this.router = this.$route.name;
  },
};
</script>
<style lang='less' scoped>
@import url("./index.less");
</style>