<template>
  <surround-box
    :width="440"
    :title="menuName"
    background="rgba(1, 12, 10, 0.8)"
  >
    <div class="product-model">
      <div class="model-item">
        <div class="model-data">
          <img src="imgs/produceModel/fn.png" alt="" />
          <div class="data-box">
            <p class="num">{{ productModel.dryManureValue }}kg</p>
            <p>今日预测鲜粪产量</p>
          </div>
          <div class="data-box">
            <p class="num">{{ productModel.urineValue }}kg</p>
            <p>今日预测尿液产量</p>
          </div>
        </div>
        <div class="model-title">
          <span>粪/尿生产模型</span>
          <span class="iconfont icon">&#xe6e4;</span>
        </div>
        <div class="model-formula">
          <div class="formula-item">
            <span class="result">G</span>
            <span>=</span>
            <span>X</span>
            <span>×</span>
            <span>C</span>
          </div>
          <div class="formula-item">
            <span class="result">L</span>
            <span>=</span>
            <span>H</span>
            <span>×</span>
            <span>C</span>
          </div>
        </div>
        <div class="formula-info">
          <p>
            G：今日鲜粪产量（<span>{{ productModel.dryManureValue }}</span
            >）
          </p>
          <p>
            X：单位鲜粪产量（<span>{{ productModel.dryManureUnitValue }}</span
            >）
          </p>
          <p>
            L：今日尿液产量（<span>{{ productModel.urineValue }}</span
            >）
          </p>
          <p>
            H：单位尿液产量（<span>{{ productModel.urineUnitValue }}</span
            >）
          </p>
          <p>
            C：存栏数量（<span>{{ productModel.num }}</span
            >）
          </p>
        </div>
      </div>
      <div class="model-item">
        <div class="model-data">
          <img src="imgs/produceModel/N2.png" alt="" />
          <div class="data-box">
            <p class="num">2623kg</p>
            <p>今日预测氮(N)产量</p>
          </div>
        </div>
        <div class="model-title">
          <span>氮养分生产模型</span>
          <span class="iconfont icon">&#xe6e4;</span>
        </div>
        <div class="model-formula">
          <div class="formula-item">
            <span class="result">N</span>
            <span>=</span>
            <span>C</span>
            <span>×</span>
            <span>R</span>
          </div>
        </div>
        <div class="formula-info">
          <p>
            N：今日预测氮(N)产量（<span>{{ productModel.elementAmount }}</span
            >）
          </p>
          <p>
            C：存栏数量（<span>{{ productModel.num }}</span
            >）
          </p>
          <p>
            R：每千克粪污氮养分含量（<span>{{
              productModel.elementUnitValue
            }}</span
            >）
          </p>
        </div>
      </div>
      <!-- <div class="model-item">
        <div class="model-data">
          <img src="imgs/produceModel/xf2.png" alt="" />
          <div class="data-box">
            <p class="num">2623kg</p>
            <p>今日预测鲜粪产量</p>
          </div>
        </div>
        <div class="model-title">
          <span>鲜粪生产模型</span>
          <span class="iconfont icon">&#xe6e4;</span>
        </div>
        <div class="model-formula">
          <div class="formula-item">
            <span class="result">F</span>
            <span>=</span>
            <span>Z</span>
            <span>×</span>
            <span>D</span>
          </div>
        </div>
        <div class="formula-info">
          <p>F：今日预测鲜粪产量（<span>3200</span>）</p>
          <p>Z：单位鲜粪产量（<span>84</span>）</p>
          <p>D：动物单位（<span>38.1</span>）</p>
        </div>
      </div> -->
    </div>
  </surround-box>
</template>

<script>
import surroundBox from "@/components/mapComp/surroundBox2";
export default {
  name: "",
  components: {
    surroundBox,
  },
  props: {
    menuName: {
      type: String,
      default: "",
    },
    farmId: [String, Number],
  },
  data() {
    return {
      productModel: {},
    };
  },
  methods: {
    // 查看模型详情
    checkProduceModel() {
      if (!this.farmId) return;
      this.$post(
        this.$api.MANURE_PRODUCTION_MODEL.VIEW.replace("{id}", this.farmId)
      ).then((res) => {
        this.productModel = res;
      });
    },
  },
  mounted() {
    this.checkProduceModel();
  },
};
</script>

<style lang="less" scoped>
@import url("./index.less");
</style>