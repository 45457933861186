function PictureLink(point, pictureUrl, options = {}) {
    this._point = point;
    this._pictureUrl = pictureUrl;
    let offset = options.offset || [100, 50]
    this._offsetX = offset[0]
    this._offsetY = offset[1]
    this._width = options.width || 80
    this._height = options.height || 60
    this._events = {}
}

// 继承API的BMap.Overlay
PictureLink.prototype = new BMapGL.Overlay();
PictureLink.prototype.initialize = function (map) {
    this._map = map;
    let div = document.createElement('div')
    div.style.position = 'absolute'
    div.style.width = this._width + Math.abs(this._offsetX) + 'px'
    div.style.height = this._height / 2 + Math.abs(this._offsetY) + 'px'
    div.classList = ['picture-preview-class']
    let img = document.createElement('div')
    img.style.width = this._width + 'px'
    img.style.height = this._height + 'px'
    img.style.position = 'absolute'
    img.style.backgroundImage = `url(${this._pictureUrl})`
    img.style.backgroundSize = 'cover'
    img.style.boxShadow = '0 0 2px 2px #afafaf'
    // let img = document.createElement('img')
    // img.style.width = this._width + 'px'
    // img.style.height = this._height + 'px'
    // img.style.position = 'absolute'
    // img.src = this._pictureUrl
    if (this._offsetX >= 0) img.style.right = '0px'
    else img.style.left = '0px'
    if (this._offsetY >= 0) img.style.bottom = '0px'
    else img.style.top = '0px'
    div.appendChild(img)
    let svg = document.createElementNS("http://www.w3.org/2000/svg", 'svg')
    svg.setAttribute('viewBox', `0 0 ${Math.abs(this._offsetX)} ${Math.abs(this._offsetY)}`)
    svg.style.width = Math.abs(this._offsetX) + 'px'
    svg.style.height = Math.abs(this._offsetY) + 'px'
    svg.style.position = 'absolute'
    svg.style.pointerEvents = 'none'
    if (this._offsetX > 0 && this._offsetY > 0) {
        svg.style.left = '0px'
        svg.style.top = "0px"
        let path = document.createElementNS("http://www.w3.org/2000/svg", 'path')
        path.setAttribute('d', `M 16,2 L ${Math.abs(this._offsetX) - 2},${Math.abs(this._offsetY) - 2}`)
        path.setAttribute('stroke', '#fff')
        path.setAttribute('strokeWidth', '1px')
        path.setAttribute('fill', 'none')
        svg.appendChild(path)
        let circle1 = document.createElementNS("http://www.w3.org/2000/svg", 'circle')
        circle1.setAttribute('cx', '16')
        circle1.setAttribute('cy', '2')
        circle1.setAttribute('r', '2')
        circle1.setAttribute('fill', '#bbb')
        circle1.setAttribute('strokeWidth', '0')
        svg.appendChild(circle1)
        let circle2 = document.createElementNS("http://www.w3.org/2000/svg", 'circle')
        circle2.setAttribute('cx', Math.abs(this._offsetX) - 2)
        circle2.setAttribute('cy', Math.abs(this._offsetY) - 2)
        circle2.setAttribute('r', '2')
        circle2.setAttribute('fill', '#bbb')
        circle2.setAttribute('strokeWidth', '0')
        svg.appendChild(circle2)
        div.appendChild(svg)
    } else if (this._offsetX > 0 && this._offsetY < 0) {
        svg.style.left = '0px'
        svg.style.bottom = "0px"
        let path = document.createElementNS("http://www.w3.org/2000/svg", 'path')
        path.setAttribute('d', `M 16,${Math.abs(this._offsetY) - 2} L ${Math.abs(this._offsetX) - 2},2`)
        path.setAttribute('stroke', '#fff')
        path.setAttribute('strokeWidth', '1px')
        path.setAttribute('fill', 'none')
        svg.appendChild(path)
        let circle1 = document.createElementNS("http://www.w3.org/2000/svg", 'circle')
        circle1.setAttribute('cx', '16')
        circle1.setAttribute('cy', Math.abs(this._offsetY) - 2)
        circle1.setAttribute('r', '2')
        circle1.setAttribute('fill', '#bbb')
        circle1.setAttribute('strokeWidth', '0')
        svg.appendChild(circle1)
        let circle2 = document.createElementNS("http://www.w3.org/2000/svg", 'circle')
        circle2.setAttribute('cx', Math.abs(this._offsetX) - 2)
        circle2.setAttribute('cy', 2)
        circle2.setAttribute('r', '2')
        circle2.setAttribute('fill', '#bbb')
        circle2.setAttribute('strokeWidth', '0')
        svg.appendChild(circle2)
        div.appendChild(svg)
    } else if (this._offsetX < 0 && this._offsetY > 0) {
        svg.style.right = '0px'
        svg.style.top = "0px"
        let path = document.createElementNS("http://www.w3.org/2000/svg", 'path')
        path.setAttribute('d', `M 2,${Math.abs(this._offsetY) - 2}  L ${Math.abs(this._offsetX) - 2 - 14},2`)
        path.setAttribute('stroke', '#fff')
        path.setAttribute('strokeWidth', '1px')
        path.setAttribute('fill', 'none')
        svg.appendChild(path)
        let circle1 = document.createElementNS("http://www.w3.org/2000/svg", 'circle')
        circle1.setAttribute('cx', '2')
        circle1.setAttribute('cy', Math.abs(this._offsetY) - 2)
        circle1.setAttribute('r', '2')
        circle1.setAttribute('fill', '#bbb')
        circle1.setAttribute('strokeWidth', '0')
        svg.appendChild(circle1)
        let circle2 = document.createElementNS("http://www.w3.org/2000/svg", 'circle')
        circle2.setAttribute('cx', Math.abs(this._offsetX) - 2 - 14)
        circle2.setAttribute('cy', 2)
        circle2.setAttribute('r', '2')
        circle2.setAttribute('fill', '#bbb')
        circle2.setAttribute('strokeWidth', '0')
        svg.appendChild(circle2)
        div.appendChild(svg)
    } else if (this._offsetX < 0 && this._offsetY < 0) {
        svg.style.right = '0px'
        svg.style.bottom = "0px"
        let path = document.createElementNS("http://www.w3.org/2000/svg", 'path')
        path.setAttribute('d', `M 2,2 L ${Math.abs(this._offsetX) - 2 - 14},${Math.abs(this._offsetY) - 2}`)
        path.setAttribute('stroke', '#fff')
        path.setAttribute('strokeWidth', '1px')
        path.setAttribute('fill', 'none')
        svg.appendChild(path)
        let circle1 = document.createElementNS("http://www.w3.org/2000/svg", 'circle')
        circle1.setAttribute('cx', '2')
        circle1.setAttribute('cy', 2)
        circle1.setAttribute('r', '2')
        circle1.setAttribute('fill', '#bbb')
        circle1.setAttribute('strokeWidth', '0')
        svg.appendChild(circle1)
        let circle2 = document.createElementNS("http://www.w3.org/2000/svg", 'circle')
        circle2.setAttribute('cx', Math.abs(this._offsetX) - 2 - 14)
        circle2.setAttribute('cy', Math.abs(this._offsetY) - 2)
        circle2.setAttribute('r', '2')
        circle2.setAttribute('fill', '#bbb')
        circle2.setAttribute('strokeWidth', '0')
        svg.appendChild(circle2)
        div.appendChild(svg)
    }
    this._map.getPanes().markerPane.appendChild(div)
    this._div = div
    div.addEventListener('click', () => {
        if (this._events.click) {
            this._events.click.forEach(item => {
                item(this)
            })
        }
    })
    return div
}
PictureLink.prototype.draw = function () {
    let position = this._map.pointToOverlayPixel(this._point);
    let width = this._width + Math.abs(this._offsetX)
    let height = this._height / 2 + Math.abs(this._offsetY)
    if (this._offsetX > 0 && this._offsetY > 0) {
        this._div.style.left = position.x + 'px'
        this._div.style.top = position.y + 'px'
    } else if (this._offsetX > 0 && this._offsetY < 0) {
        this._div.style.left = position.x + 'px'
        this._div.style.top = position.y - height + 'px'
    } else if (this._offsetX < 0 && this._offsetY > 0) {
        this._div.style.left = position.x - width + 'px'
        this._div.style.top = position.y + 'px'
    } else if (this._offsetX < 0 && this._offsetY < 0) {
        this._div.style.left = position.x - width + 'px'
        this._div.style.top = position.y - height + 'px'
    }
}
PictureLink.prototype.hide = function () {
    if (this._div)
        this._div.style.display = 'none'
}
PictureLink.prototype.show = function () {
    if (this._div)
        this._div.style.display = 'block'
}

PictureLink.prototype.addEventListener = function (type, callback) {
    if (this._events[type]) this._events[type].push(callback)
    else this._events[type] = [callback]
}

export default PictureLink