<template>
  <surround-box>
    <div class="base-info-container">
      <div class="base-info-title">企业介绍</div>
      <div class="base-info-content-container">
        <div class="base-info-detail" v-html="content"></div>
      </div>
    </div>
  </surround-box>
</template>
<script>
import surroundBox from "@/components/mapComp/surroundBox";

export default {
  props: {
    content: String,
  },
  components: {
    surroundBox,
  },
};
</script>
<style lang='less' scoped>
@import url("./companyInfo.less");
</style>