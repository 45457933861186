<template>
  <div class="page-container">
    <!-- <base-map
      @farm-click="farmClick"
      :farmFilter="farmFilter"
      :farms="farms"
      :currentFarm="currentFarm"
    ></base-map> -->
    <base-map
        v-if="mapType == 'img'"
        ref="baseMap"
        :boundaryName="boundaryName"
        :center="center"
        :farmFilterDefault="farmFilter"
        :stopDefaultFarmClick="stopDefaultFarmClick"
        :zoom="zoom"
        @farm-click="farmClick"
        @site-click="siteClick"
        @device-click="deviceClick"
    ></base-map>
    <normal-map
        v-if="mapType == 'normal' && !isCover"
        ref="baseMap"
        :boundaryName="boundaryName"
        :center="center"
        :farmFilterDefault="farmFilter"
        :hideChina="hideChina"
        :showPicture="showPicture"
        :stopDefaultFarmClick="stopDefaultFarmClick"
        :useType="useType"
        :zoom="zoom"
        @farm-click="farmClick"
        @site-click="siteClick"
        @device-click="deviceClick"
    ></normal-map>
    <mapboxMap
        ref="mapboxMap"
        style="{'opacity': showMap === 2 ? 1 : 0}"
        :center="center"
        @mapReady="mapboxReady"></mapboxMap>
<!--    <img v-if="currentFarm && currentFarm.coverings && isCover"-->
<!--         :src="'https://castoff.hogdata.cn' + currentFarm.coverings"-->
<!--         alt=""-->
<!--         style=" position: absolute;top: 0;left: 0; z-index: 1;width: 100%;height: 100%;}"-->
<!--    >-->
    <div :class="[{'background-color-useTypeOne': useType == 1}]" class="mask-background"></div>
    <template v-if="currentFarm">
      <small-weather
          :lat="currentCoordinates.lat"
          :lon="currentCoordinates.lon"
          :title="currentFarm.sourceName"
      ></small-weather>
      <div class="company-return">
        <div class="return-button iconfont" @click="back">
          <span>&#xe671;</span>
          <span>返回</span>
        </div>
        <!--        <div class="company-select">-->
        <!--          <Cascader-->
        <!--            transfer-->
        <!--            placeholder-->
        <!--            @on-change="changeFarm"-->
        <!--            v-model="currentCompanyFarm"-->
        <!--            style="width: 348px"-->
        <!--            :data="companies"-->
        <!--          >-->
        <!--          </Cascader>-->
        <!--        </div>-->
      </div>
      <!-- <div id="sitefilter" class="legend-box">
        <legend-pan
          :farmId="currentFarm ? currentFarm.id : ''"
          :filter="siteFilter"
          @clear="siteFilterClear"
          @filter="siteFilterAction"
        ></legend-pan>
      </div> -->
      <div class="menu-pane">
        <menuPane
            :current="!componentBind.type ? currentMenu : componentBind.type"
            :type="currentFarm ? currentFarm.farmType : -1"
            @on-change="currentMenuChange"
        ></menuPane>
      </div>
      <div class="farm-info-pane">
        <!-- <component
          v-bind="componentBind"
          :is="currentMenu"
          :longitude="currentCoordinates.lon"
          :latitude="currentCoordinates.lat"
          :menuName="currentMenuName"
          :currentFarm="currentFarm"
          :currentSite="currentSite"
        ></component> -->
        <component
            :is="currentMenu"
            v-bind="componentBind"
            v-on="componentEvent"
        ></component>
      </div>
    </template>
    <device-info-modal
        v-model="DeviceInfoModalData.show"
        :data="DeviceInfoModalData.data"
    ></device-info-modal>
  </div>
</template>
<script>
import BaseMap from "@/components/map/map";
import normalMap from "@/components/map/normalMap";
import menuPane from "./MenuPane";
import smallWeather from "./smallWeather";
import baseInfo from "./BaseInfo";
import WeatherPrewarning from "./weather";
import productModel from "./productModel";
import sewageModel from "./sewageModel";
import landCarryCapacityModel from "./landCarryCapacityModel";
import equipmentWarn from "./equipmentWarn";
import videoMonitor from "./videoMonitor";
import sensorPane from "./sensor";
import statistics from "./statistics";
import DeviceManage from "./DeviceManage";
import Facilities from "./Facilities";
import ShouNa from "./ShouNa";
import JieGan from "./JieGan";
import ZhuaShou from "./ZhuaShou";
import BaoZhuang from "./BaoZhuang";
// 显示设备信息的弹窗
import DeviceInfoModal from "./DeviceManage/infoModal";
import {mapState} from "vuex";
import mapboxMap from "@/components/map/mapbox"
export default {
  props: {
    stopDefaultFarmClick: {
      type: Boolean,
      default: false,
    },
    farmFilter: Set,
    hideChina: Boolean,
    mapType: {
      type: String,
      default: "img",
    },
    zoom: {
      type: Number,
      default: 11,
      // default: 5,
    },
    // 加载区域范围
    boundaryName: {
      type: String,
      default: "重庆市荣昌区",
    },
    center: {
      type: Array,
      default() {
        return [105.52314042411466, 29.475651579673745];
        // return [117.19635, 36.24093];
      },
    },
    showPicture: {
      type: Boolean,
      default: false,
    },
    useType: {
      default: null
    },
    isCover: {
      type: Boolean,
      default: false
    }
  },
  components: {
    BaseMap,
    normalMap,
    smallWeather,
    menuPane,
    baseInfo,
    WeatherPrewarning,
    productModel,
    sewageModel,
    landCarryCapacityModel,
    equipmentWarn,
    videoMonitor,
    sensorPane,
    statistics,
    DeviceManage,
    Facilities,
    DeviceInfoModal,
    ShouNa,
    JieGan,
    ZhuaShou,
    BaoZhuang,
    mapboxMap
  },
  data() {
    return {
      // farms: [],
      // 基地图例筛选
      tempfarmFilter: new Set(),
      // 设备图例筛选
      // siteFilter: new Set(),
      // 当前展示基地
      currentFarm: null,
      // 当前展示设备点
      currentSite: null,
      // 当前经纬度
      currentCoordinates: {
        lon: 0,
        lat: 0,
      },
      // 公司列表
      companies: [],
      // 当前查看的公司/基地
      currentCompanyFarm: [],
      // 当前选中的物联网菜单
      currentMenu: "baseInfo",
      currentMenuName: "基础信息",

      // 组件绑定数据
      componentBind: {},
      // 组件绑定时间监听
      componentEvent: {},

      // 设备展示弹窗
      DeviceInfoModalData: {
        show: false,
        data: null,
      },
      // boundaryNames: "重庆市荣昌区",
      //基地id
      farmIds: "",
      //设备类型
      sensorType: "",
      deviceList: [],
    };
  },
  computed: {
    ...mapState({
      userCategory: (state) => state.user.user.category,
      farms: (state) => state.map.farmList,
      farmReady: (state) => state.map.farmReady,
    }),
    // 传感器列表
    // sensor() {
    //   if (!this.currentFarm) return [];
    //   let all = this.$store.state.map.farmSites.get(this.currentFarm.sourceId);
    //   if (!all) return [];
    //   return all.filter((item) => item.deviceType == 1);
    // },
    // 粪污生长监测设备列表
    feces() {
      if (!this.currentFarm) return [];
      this.sensorType = 1;
      // let all = this.$store.state.map.farmSites.get(this.currentFarm.sourceId);
      // if (!all) return [];
      // return all.filter(
      //   (item) => item.deviceGuid == "1b630463-f98f-46a9-91dc-0253b0773cd9"
      // );
    },
    moisture() {
      if (!this.currentFarm) return [];
      this.sensorType = 2;
      // let all = this.$store.state.map.farmSites.get(this.currentFarm.sourceId);
      // if (!all) return [];
      // return all.filter(
      //   (item) => item.deviceGuid == "842f02f5-efa0-4918-ac4d-d149402b4c63"
      // );
    },
    waters() {
      if (!this.currentFarm) return [];
      this.sensorType = 3;
      // let all = this.$store.state.map.farmSites.get(this.currentFarm.sourceId);
      // if (!all) return [];
      // return all.filter(
      //   (item) => item.deviceGuid == "db73194c-3c46-4cbc-a6b2-9645bc6eedf5"
      // );
    },
  },
  watch: {
    farmReady: {
      handler(val) {
        if (val) {
          this.getCompany();
        }
      },
      immediate: true,
    },
    farmFilter: {
      handler(filter) {
        if (filter) {
          this.$refs.baseMap && this.$refs.baseMap.setFilter(filter);
        }
      },
      immediate: true,
    },
    // currentFarm(val) {
    //   console.log(val);
    // },
  },
  methods: {
    //   查询基地列表
    // getFarms() {
    //   this.$post(this.$api.MAP.MAP_CFG_INFO).then((res) => {
    //     this.farms = res.list.filter((item) => item.mapCenterLocation);
    //     this.getCompany();
    //   });
    // },
    // 基地图例筛选
    farmFilterAction(siteType) {
      let filter = new Set(this.farmFilter.values());
      if (filter.has(siteType)) filter.delete(siteType);
      else filter.add(siteType);
      this.farmFilter = filter;
    },
    //设备图例筛选
    siteFilterAction(siteType) {
      let filter = new Set(this.farmFilter.values());
      if (filter.has(siteType)) filter.delete(siteType);
      else filter.add(siteType);
      this.siteFilter = filter;
    },
    // 设备图例清空
    siteFilterClear() {
      this.siteFilter = new Set();
    },
    // 基地点击
    farmClick(farm) {
      let coordinates = farm.mapCenterLocation.split(",");
      this.currentCoordinates.lon = +coordinates[0];
      this.currentCoordinates.lat = +coordinates[1];
      let company = this.companies.find(
          (item) => item.companyNo == farm.companyNo
      );
      if (company) this.currentCompanyFarm = [company.value, farm.id];
      else this.currentCompanyFarm = [];
      this.currentFarm = farm;
      this.componentBind.currentFarm = farm;
      this.$emit("farm-change", farm);
    },
    //设备列表
    getDeviceList() {
      return this.$post(this.$api.MONITOR.TREATMENT_EFFECT_MONITOR, {
        farmId: this.currentFarm.sourceId,
        sensorType: this.sensorType,
      }).then((res) => {
        this.deviceList = res.list;
        // console.log(res);
      });
    },
    // 切换基地
    changeFarm(value) {
      if (!value) return;
      let farmId = value[1];
      let farm = this.farms.find((item) => item.id == farmId);
      this.$refs.baseMap.farmClick(farm, true);
      this.currentFarm = farm;
      this.$emit("farm-change", farm);
      // if (!this.currentFarm) this.$emit("farm-click", farm);
      // if (farm && farm.id != this.currentFarm.id)
      //   this.$emit("farm-click", farm);
    },
    // 设备点点击
    siteClick(site) {
      // 粪污生产
      if (site.deviceGuid === "1b630463-f98f-46a9-91dc-0253b0773cd9") {
        this.currentMenuName = "粪污生产";
        this.componentBind = {
          type: "feces",
          menuName: this.currentMenuName,
          list: this.feces,
          currentSiteGuid: site.guid,
        };
        this.currentMenu = "sensorPane";
      }
      // 土壤墒情
      if (site.deviceGuid === "842f02f5-efa0-4918-ac4d-d149402b4c63") {
        this.currentMenuName = "土壤墒情";
        this.componentBind = {
          type: "soil",
          menuName: this.currentMenuName,
          list: this.moistures,
          currentSiteGuid: site.guid,
        };
        this.currentMenu = "sensorPane";
      }
      // 水质监测
      if (site.deviceGuid === "db73194c-3c46-4cbc-a6b2-9645bc6eedf5") {
        this.currentMenuName = "水体质量";
        this.componentBind = {
          type: "water",
          menuName: this.currentMenuName,
          list: this.waters,
          currentSiteGuid: site.guid,
        };
        this.currentMenu = "sensorPane";
      }
    },
    // 切换物联网选项
    currentMenuChange(type) {
      switch (type) {
        case "baseInfo":
          this.currentMenuName = "基础信息";
          this.componentBind = {
            currentFarm: this.currentFarm,
          };
          break;
        case "WeatherPrewarning":
          this.currentMenuName = "气象预报";
          this.componentBind = {
            longitude: this.currentCoordinates.lon,
            latitude: this.currentCoordinates.lat,
            menuName: this.currentMenuName,
          };
          break;
          // case "sensor":
          //   this.currentMenuName = "传感器数据";
        case "feces":
          this.currentMenuName = "粪污生产";
          this.currentMenu = "sensorPane";
          this.componentBind = {
            type: "feces",
            menuName: this.currentMenuName,
            list: this.feces,
            currentFarm: this.currentFarm,
            sensorType: "1",
          };
          return;
        case "soil":
          this.currentMenuName = "土壤墒情";
          this.componentBind = {
            type: "soil",
            menuName: this.currentMenuName,
            list: this.moistures,
            currentFarm: this.currentFarm,
            sensorType: "2",
          };
          this.currentMenu = "sensorPane";
          return;
        case "water":
          this.currentMenuName = "水体质量";
          this.componentBind = {
            type: "water",
            menuName: this.currentMenuName,
            list: this.waters,
            currentFarm: this.currentFarm,
            sensorType: "3",
          };
          this.currentMenu = "sensorPane";
          return;
        case "productModel":
          this.currentMenuName = "生产模型";
          this.componentBind = {
            menuName: this.currentMenuName,
            farmId: this.currentFarm.sourceId,
          };
          break;
        case "sewageModel":
          this.currentMenuName = "污水排放";
          this.componentBind = {
            menuName: this.currentMenuName,
            farmId: this.currentFarm.sourceId,
          };
          break;
        case "landCarryCapacityModel":
          this.currentMenuName = "土地承载力";
          this.componentBind = {
            menuName: this.currentMenuName,
            farmId: this.currentFarm.sourceId,
          };
          break;
        case "equipmentWarn":
          this.currentMenuName = "设备预警";
          this.componentBind = {
            menuName: this.currentMenuName,
          };
          break;
        case "videoMonitor":
          this.currentMenuName = "视频监控";
          this.componentBind = {
            menuName: this.currentMenuName,
            currentFarm: this.currentFarm,
          };
          break;
        case "videoMonitor":
          this.currentMenuName = "数据统计";
          this.componentBind = {
            menuName: this.currentMenuName,
            currentFarm: this.currentFarm,
          };
          break;
        case "DeviceManage":
          this.currentMenuName = "处理设备";
          this.componentBind = {
            menuName: this.currentMenuName,
            currentFarm: this.currentFarm,
          };
          this.componentEvent = {
            deviceClick: this.deviceClick,
          };
          break;
        case "Facilities":
          this.currentMenuName = "处理设施";
          this.componentBind = {
            menuName: this.currentMenuName,
            currentFarm: this.currentFarm,
          };
          break;
        case "ShouNa":
          this.currentMenuName = "粪污收纳统计";
          this.componentBind = {
            menuName: this.currentMenuName,
            currentFarm: this.currentFarm,
          };
          break;
        case "JieGan":
          this.currentMenuName = "秸秆收纳统计";
          this.componentBind = {
            menuName: this.currentMenuName,
            currentFarm: this.currentFarm,
          };
          break;
        case "ZhuaShou":
          this.currentMenuName = "抓手";
          this.componentBind = {
            menuName: this.currentMenuName,
            currentFarm: this.currentFarm,
          };
          break;
        case "BaoZhuang":
          this.currentMenuName = "包装";
          this.componentBind = {
            menuName: this.currentMenuName,
            currentFarm: this.currentFarm,
          };
          break;
      }
      this.currentMenu = type;
    },
    // 返回概略图
    back() {
      this.$emit("farm-change", null);
      this.currentFarm = null;
      if (this.isCover) {
        this.$refs.mapboxMap.reset()
      }else {
        this.$refs.baseMap.reset();
      }

      this.currentMenu = "baseInfo";
    },
    // 查询公司
    getCompany() {
      this.$post(this.$api.company.LIST, {
        pageNo: 1,
        pageSize: 99999,
        category: this.userCategory + 1,
      }).then((res) => {
        let companies = [];
        console.log(res, '公司')
        for (let i = 0; i < res.list.length; i++) {
          let temp = res.list[i];
          let children = this.farms.filter(
              (item) => item.companyNo == temp.companyNo
          );
          if (children.length === 0) continue;
          companies.push({
            value: temp.id,
            label: temp.name,
            companyNo: temp.companyNo,
            children: children.map((item) => ({
              value: item.id,
              label: item.sourceName,
            })),
          });
        }
        this.companies = companies;
      });
    },
    // 查看设备详情
    deviceClick(device) {
      this.DeviceInfoModalData.data = device;
      this.DeviceInfoModalData.show = true;
    },
    mapboxReady(v) {
      this.$emit('map-ready',v)
    }
  },
  mounted() {
    // this.getFarms();
    // this.boundaryNames = this.boundaryName;
  },
};
</script>
<style lang='less' scoped>
@import url("./index.less");
</style>
