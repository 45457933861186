<template>
  <surround-box :title="menuName">
    <div class="device-account">设备总数量：{{ devices.length }}</div>
    <div class="inner-content-page">
      <div
        @click="checkDeviceInfo(device)"
        class="device-item"
        v-for="device in devices"
        :key="device.id"
      >
        <div class="device-img">
          <img
            :src="
              device.img
                ? device.servicePath + device.img
                : device.categoryImg
                ? device.servicePath + device.categoryImg
                : '/imgs/empty/emptyimg.png'
            "
            alt=""
          />
        </div>
        <div class="device-status">
          <span
            :class="{
              'device-status-dot': true,
              'rest-device': device.status === 0,
              'working-device': device.status === 1,
              'disabled-device': device.status === 3,
            }"
          ></span>
          {{
            device.status === 0
              ? "闲置中"
              : device.status === 1
              ? "工作中"
              : "已废弃"
          }}
        </div>
        <div class="device-name">
          {{ device.name }}
        </div>
      </div>
    </div>
  </surround-box>
</template>

<script>
import surroundBox from "@/components/mapComp/surroundBox2";
export default {
  components: {
    surroundBox,
  },
  props: {
    menuName: String,
    currentFarm: Object,
  },
  data() {
    return {
      devices: [],
      // modalData: {
      //   show: false,
      //   data: null,
      // },
    };
  },
  methods: {
    // 获取基地设备列表
    getDevices() {
      this.$post(this.$api.DEAL_DEVICE.LIST, {
        pageNo: 1,
        pageSize: 9999,
        siteType: "2",
        farmId: this.currentFarm.sourceId,
      }).then((res) => {
        this.devices = res.list;
      });
    },
    // 查看设备详情
    checkDeviceInfo(device) {
      this.$emit("deviceClick", device);
      // this.modalData.data = device;
      // this.modalData.show = true;
    },
  },
  mounted() {
    this.getDevices();
  },
};
</script>

<style lang="less" scoped>
@import url("./index.less");
</style>