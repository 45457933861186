<template>
  <surround-box
    :title="menuName"
    :width="440"
    background="rgba(1, 12, 10, 0.8)"
  >
    <div class="video-monitor">
      <div class="sel-video">
        <span class="iconfont icon">&#xe687;</span>
        <Select v-model="videoId" style="width: 200px">
          <Option v-for="item in videoList" :value="item.id" :key="item.id">{{
            item.name
          }}</Option>
        </Select>
      </div>
      <div class="video-panel">
        <player :videoInfo="videoInfo" v-if="videoInfo"></player>
        <div class="no-data">
          <img src="imgs/empty/empty.png" alt="" />
          <p>暂无视频</p>
        </div>
      </div>
    </div>
  </surround-box>
</template>

<script>
import surroundBox from "@/components/mapComp/surroundBox2";
import player from "@/components/player";
export default {
  name: "",
  components: {
    surroundBox,
    player,
  },
  props: {
    menuName: String,
    currentFarm: Object,
  },
  data() {
    return {
      videoList: [],
      videoInfo: null,
      videoId: "",
    };
  },
  watch: {
    currentFarm: {
      immediate: true,
      handler(n) {
        if (!n) return;
        this.getList();
      },
    },
    videoId(n) {
      this.videoInfo = this.videoList.find((item) => item.id == n);
    },
  },
  methods: {
    getList() {
      // this.$post(this.$api.DEVICE_LL.AUTHORIZATION_LIST, {
      this.$post(this.$api.BUSSDEVICEVIDEO.LIST, {
        farmId: this.currentFarm.sourceId,
        // deviceGuid: "c9207b50-e61e-45be-a378-4fb61d0bcefd",
        // vesitingNo: localStorage.getItem("companyNo"),
        pageNo: 1,
        pageSize: 9999,
      }).then((res) => {
        this.videoList = res.list;
        if (res.list.length == 0) return;
        this.videoInfo = res.list[0];
        this.videoId = res.list[0].id;
      });
    },
  },
};
</script>

<style lang="less" scoped>
.video-monitor {
  width: 100%;
  padding: 16px;
  color: #fff;
  .sel-video {
    display: flex;
    align-items: center;
    .icon {
      font-size: 16px;
      padding-right: 10px;
    }
    /deep/ .ivu-select-selection {
      border: 1px solid #00fff7;
      background: linear-gradient(
        180deg,
        rgba(14, 56, 50, 0.28) 0%,
        rgba(3, 42, 34, 0.81) 100%
      );
      color: #fff;
    }
  }
  .video-panel {
    width: 100%;
    height: 250px;
    margin-top: 16px;
    .no-data {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      img {
        width: 120px;
        height: 80px;
      }
    }
  }
}
</style>