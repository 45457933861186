<template>
  <surroundBox :style="{ top: top + 'px' }" class="chatPane">
    <div class="inner">
      <a class="close" @click="close">X</a>
      <div class="header">
        <div>
          <img src="/imgs/map/gj_i.png" />
          <span class="title">{{ currentSiteInfo.name }}-变化曲线</span>
        </div>
        <div style="display: flex; align-items: center">
          <div class="granularity" v-if="type == 1">
            <span
              :class="[
                'granularity-item',
                switchType === 2 ? 'granularity-active' : '',
              ]"
              @click="() => cut(2)"
              >小时</span
            >
            <span
              :class="[
                'granularity-item',
                switchType === 3 ? 'granularity-active' : '',
              ]"
              @click="() => cut(3)"
              >天</span
            >
            <span
              :class="[
                'granularity-item',
                switchType === 4 ? 'granularity-active' : '',
              ]"
              @click="() => cut(4)"
              >月</span
            >
          </div>
          <DatePicker
            v-if="type == 2"
            v-model="time"
            type="daterange"
            :options="options"
            placement="bottom-end"
            style="width: 220px; margin-right: 20px"
            @on-change="() => getChartData(guids)"
          ></DatePicker>
          <span class="sel-term" v-if="type == 1" @click="termCut(2)"
            >自定义日期范围</span
          >
          <span class="sel-term" v-if="type == 2" @click="termCut(1)"
            >固定筛选条件</span
          >
          <Button type="primary" @click="exportData">导出</Button>
          <!-- <Select
            :value="currentSensorInfo.guid"
            style="width: 150px; float: right"
            @on-change="snesorChange"
          >
            <Option
              v-for="item in sensors"
              :key="item.guid"
              :value="item.guid"
              >{{ item.name }}</Option
            >
          </Select> -->
        </div>
      </div>
      <div class="accumulated-rainfall" v-if="flag">
        <span>积雨量：</span>
        <span>{{ rainfall.toFixed(2) }}mm</span>
      </div>
      <div class="chart" ref="sensorChart"></div>
    </div>
  </surroundBox>
</template>
<script>
import * as echarts from "echarts";
import surroundBox from "@/components/mapComp/surroundBox";
import CU from "@/common/util";
import moment from "moment";

export default {
  name: "chartPane",
  components: {
    surroundBox,
  },

  props: {
    data: Object,
    top: {
      type: Number,
      default: 130,
    },
  },

  data() {
    return {
      lineChartData: {},
      currentSiteInfo: {},
      currentSensorInfo: {},
      sensors: [],
      switchType: 3,
      guids: [],

      type: 1,
      options: {
        disabledDate(date) {
          if (moment(date).isAfter(moment(new Date()).subtract(1, "days")))
            return true;
          return false;
        },
      },
      time: [],
      flag: false,
      rainfall: 0,
    };
  },

  methods: {
    close() {
      this.$emit("closePane");
    },

    // snesorChange(val) {
    //   let sensor = this.sensors.filter((el) => el.guid === val)[0] || {};
    //   this.currentSensorInfo = sensor;
    // },
    termCut(type) {
      if (this.type == type) return;
      this.type = type;
      this.time = [];
    },

    cut(type) {
      if (this.switchType === type) return;
      this.switchType = type;
      this.getChartData(this.guids);
    },

    getChartData(guids, key) {
      this.$post(this.$api.MONITOR.MONITOR_CHART_NEW, {
        guid: guids.join(","),
        pageSize:
          this.type == 2
            ? ""
            : this.switchType === 4
            ? 12
            : this.switchType === 2
            ? 24
            : 14,
        pageNo: this.type == 2 ? "" : 1,
        switchType: this.type == 2 ? "5" : this.switchType + "",
        sBeginTime:
          this.type == 1 ? "" : moment(this.time[0]).format("YYYY-MM-DD"),
        sEndTime:
          this.type == 1 ? "" : moment(this.time[1]).format("YYYY-MM-DD"),
        days:
          this.type == 1
            ? ""
            : moment(this.time[1]).diff(moment(this.time[0]), "days") + 1,
        // sourceTpe: 0,
      }).then((res) => {
        // debugger
        // console.log(res);
        this.rainfall = 0;
        res.forEach((item) => {
          if (item.name.indexOf("雨量") > -1) {
            this.flag = true;
            item.child.forEach((el) => {
              this.rainfall += +el.value;
            });
          }
        });
        this.lineChartData = res || [];
        this.initSoilLine(key);
      });
    },

    initSoilLine(key) {
      let xData = this.lineChartData[0].child.map(
        (el) =>
          `${this.switchType === 4 && el.showTime === 0 ? 12 : el.showTime} ${
            this.switchType === 2 ? "时" : this.switchType === 3 ? "日" : "月"
          }`
      );
      // let yData = [];
      // try {
      //   // yData= this.lineChartData.child.map((el, i) => el.value || this.lineChartData.child[i -1].value);
      //   this.lineChartData.child.forEach((item, index) => {
      //     if (!item.value || item.value === "0") return;
      //     yData.push([index, item.value]);
      //   });
      // } catch (e) {
      //   console.log(e);
      // }
      let myChart = echarts.init(this.$refs.sensorChart);
      let option = {
        grid: {
          top: 50,
          left: "3%",
          right: "4%",
          bottom: "3%",
          containLabel: true,
        },
        tooltip: {
          trigger: "axis",
        },
        legend: {
          data: this.lineChartData.map((item) => item.name),
          textStyle: {
            color: "#fff",
          },
        },
        xAxis: {
          type: "category",
          data: xData,
          axisLine: {
            lineStyle: {
              color: "#fbfbfb",
            },
          },
        },
        yAxis: {
          type: "value",
          splitLine: {
            show: false,
          },
          axisLine: {
            lineStyle: {
              color: "#fbfbfb",
            },
          },
        },
        color: [
          "#08DDDD",
          "#00ff19",
          "#c7ff00",
          "#ffb500",
          "#ff0000",
          "#ae00ff",
          "#0900ff",
          "#013f0d",
          "#2a7f81",
          "#66812a",
        ],
        // series: [
        //   {
        //     data: yData,
        //     type: "line",
        //     smooth: true,
        //   },
        // ],
        series: this.lineChartData.map((item) => {
          let obj = {
            data: [],
            type: "line",
            smooth: true,
            name: item.name,
          };
          item.child.forEach((el) => {
            if (!el.value || el.value === "0") {
              obj.data.push("");
            } else {
              obj.data.push(el.value);
            }
          });
          return obj;
        }),
      };
      myChart.setOption(option);
    },

    exportData() {
      let columns = this.lineChartData.map(
        (item) => `${item.name}(${item.sensorCategoryName})`
      );
      columns.unshift("");
      let data = [];
      for (let i = 0; i < this.lineChartData[0].child.length; i++) {
        data[i] = [this.lineChartData[0].child[i].showAllTime];
        this.lineChartData.forEach((item) => {
          data[i].push(+item.child[i].value);
        });
      }
      data.push(["积雨量(mm)", this.rainfall]);
      /**
       * s:开始的单元格
       * e:结束的单元格
       * r:row, 表示行号，从0计
       * c:col, 表示列号，从0计
       */
      let merge = [
        {
          s: { r: data.length, c: 1 },
          e: { r: data.length, c: columns.length - 1 },
        },
      ];
      CU.exportExcel(columns, data, "数据监测", true, merge);
    },
  },

  mounted() {
    this.sensors = this.data.sensorList || [];
    this.currentSiteInfo = this.data.siteInfo || {};
    this.guids = this.data.sensorList;
    this.getChartData(this.guids);
    // this.currentSensorInfo = { ...this.data };
    // if (this.data.guid) this.getChartData(this.data.guid)
  },

  watch: {
    data: {
      handler(n, o) {
        this.sensors = n.sensorList || [];
        this.currentSiteInfo = n.siteInfo || {};
        this.guids = this.data.sensorList;
        this.getChartData(this.guids);
        // this.currentSensorInfo = { ...n };
        // if (n.guid) this.getChartData(n.guid)
      },
      deep: true,
    },
    // currentSensorInfo(n, o) {
    //   if (n.guid) {
    //     if (n.guid) this.getChartData(n.guid);
    //   }
    // },
  },
};
</script>
<style lang="less" scoped>
.chatPane {
  position: fixed;
  left: 50%;
  transform: translateX(-50%);
  width: calc(~"100vw - 948px");
  height: 400px;
  background: rgba(0, 0, 0, 0.5);
  color: #fff;
  .inner {
    padding: 2px 20px 20px 15px;
    position: relative;
    .close {
      position: absolute;
      top: 8px;
      right: 10px;
    }
    .header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin: 10px 0;
      // font-weight: bold;
      padding-right: 16px;
      .title {
        font-size: 16px;
        color: #08dddd;
      }
      img {
        height: 22px;
        margin-right: 5px;
        vertical-align: middle;
      }
      .granularity {
        margin-right: 20px;
        .granularity-item {
          padding: 4px 12px;
          margin-left: 10px;
          border-radius: 4px;
          border: 1px solid #fff;
          cursor: pointer;
        }
        .granularity-active {
          color: #08dddd;
          border-color: #08dddd;
        }
      }
      .sel-term {
        color: #08dddd;
        cursor: pointer;
        margin-right: 10px;
      }
    }
    .accumulated-rainfall {
      width: 100%;
      text-align: right;
    }
    .chart {
      height: 320px;
    }
  }
}
</style>