<template>
  <Modal
    :width="620"
    :value="value"
    @input="(val) => $emit('input', val)"
    footer-hide
  >
    <div v-if="data" class="device-info-form">
      <div class="info-img" v-if="data.img">
        <img :src="data.servicePath + data.img" alt="" />
      </div>
      <Row style="padding: 8px 0">
        <Col style="text-align: right" span="5"> 设备名称： </Col>
        <Col span="7">
          {{ data.name }}
        </Col>
        <Col style="text-align: right" span="5"> 设备类型： </Col>
        <Col span="7">
          {{ data.categoryName }}
        </Col>
      </Row>
      <Row style="padding: 8px 0">
        <Col style="text-align: right" span="5"> 设备数量： </Col>
        <Col span="7">
          {{ data.num }}
        </Col>
        <Col style="text-align: right" span="5"> 计量单位： </Col>
        <Col span="7">
          {{ data.unit }}
        </Col>
      </Row>
      <Row style="padding: 8px 0">
        <Col style="text-align: right" span="5"> 投入使用时间： </Col>
        <Col span="7">
          {{ data.firstUseTime.substring(0, 10) }}
        </Col>
        <Col style="text-align: right" span="5"> 设备状态： </Col>
        <Col span="7">
          {{
            data.status === 0
              ? "闲置中"
              : data.status === 1
              ? "工作中"
              : "已废弃"
          }}
        </Col>
      </Row>
      <Row style="padding: 8px 0">
        <Col style="text-align: right" span="5"> 设备规格： </Col>
        <Col span="7">
          {{ data.spec }}
        </Col>
        <Col style="text-align: right" span="5"> 处理能力： </Col>
        <Col span="7">
          {{ data.deal }}
        </Col>
      </Row>
    </div>
  </Modal>
</template>

<script>
export default {
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    data: Object,
  },
};
</script>

<style lang="less" scoped>
.device-info-form {
  width: 100%;
  font-size: 16px;

  .info-img {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-bottom: 8px;

    img {
      width: 120px;
      height: 120px;
    }
  }
}
</style>