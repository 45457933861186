<template>
  <surround-box :width="350" title="包装">
    <div class="baozhuang">
      <div class="baozhuang-content">
        <div class="conts-item">
          <div class="conts-item-top">
            <div class="item-img">
              <img src="/imgs/baozhuang.png" alt="" />
              <div class="detail-info">
                <span
                  style="
                    color: rgba(0, 255, 247, 1);
                    font-weight: bold;
                    font-size: 26px;
                  "
                  >120</span
                >
                <span style="color: #fff">本月包装次数</span>
              </div>
              <div class="select">
                <Select style="width: 60px" placeholder=" ">
                  <Option value="1">8月</Option>
                </Select>
              </div>
            </div>
          </div>
          <div class="center-pane">
            <Table
              :data="table.data"
              :columns="table.columns"
              :height="170"
              disabled-hover
              highlight-row
            ></Table>
          </div>
        </div>
      </div>
    </div>
  </surround-box>
</template>

<script>
import surroundBox from "@/components/mapComp/surroundBox2.vue";
export default {
  components: {
    surroundBox,
  },
  name: "",
  props: {
    currentFarm: Object,
  },
  data() {
    return {
      table: {
        data: [
          {
            time: "2021-08-04",
            val: "20",
          },
          {
            time: "2021-08-03",
            val: "30",
          },
          {
            time: "2021-08-02",
            val: "40",
          },
          {
            time: "2021-08-01",
            val: "20",
          },
          {
            time: "2021-07-31",
            val: "30",
          },
          {
            time: "2021-07-30",
            val: "20",
          },
        ],
        columns: [
          {
            title: "日期",
            align: "center",
            key: "time",
          },
          {
            title: "包装次数",
            align: "center",
            key: "val",
          },
        ],
      },
    };
  },
  watch: {},
  methods: {},
  mounted() {},
};
</script>

<style lang="less" scoped>
/deep/ .ivu-table {
  background-color: transparent;
}

/deep/ .ivu-table th {
  background: rgba(0, 0, 0, 0.2);
  color: #fff;
}
/deep/ .ivu-table td {
  background: transparent;
  color: #fff;
}
/deep/.ivu-table th {
  border-bottom: none;
}
/deep/ .ivu-table td {
  border-bottom: none;
}
/deep/ .ivu-table:before {
  background-color: transparent;
}
/deep/ .ivu-table-overflowY {
  &::-webkit-scrollbar {
    display: none;
  }
}
.baozhuang {
  width: 100%;
  height: 100%;
  padding: 16px;
  .baozhuang-content {
    width: 100%;
    height: 100%;
    border-radius: 8px;
    .conts-item {
      width: 100%;
      .conts-item-top {
        display: flex;
        justify-content: center;
        // border: 1px solid red;
        position: relative;
        height: 100px;
        .item-img {
          display: flex;
          justify-content: space-between;
          width: 52%;
          height: 100%;
          img {
            width: 60px;
            height: 50px;
            margin-top: 10px;
          }
        }
        .detail-info {
          display: flex;
          flex-direction: column;
          span {
            text-align: center;
            margin-bottom: 10px;
          }
        }
        .select {
          position: absolute;
          right: 0;
          top: 0;
        }
      }
      .center-pane {
        width: 100%;
      }
    }
  }
}
</style>