<template>
  <surround-box
    :title="menuName"
    :width="440"
    background="rgba(1, 12, 10, 0.8)"
  >
    <div class="sewage-model">
      <div class="model-item">
        <div class="model-data">
          <img src="imgs/produceModel/pw.png" alt="" />
          <div class="data-box">
            <p class="num">2623m³</p>
            <p>今日最高允许排水</p>
          </div>
          <div class="data-box">
            <p class="num">1623m³</p>
            <p>今日已排水(实时)</p>
          </div>
        </div>
        <div class="model-title">
          <span>污水排放计算模型</span>
          <span class="iconfont icon">&#xe6e4;</span>
        </div>
        <div class="model-formula">
          <div class="formula-item">
            <span class="result">W</span>
            <span>=</span>
            <div class="division">
              <p>C</p>
              <p class="line"></p>
              <p>Φ</p>
            </div>
            <span>×</span>
            <span>L</span>
          </div>
        </div>
        <div class="formula-info">
          <p>
            W：最高允许排水量（<span>{{ info.maxValue }}</span
            >）
          </p>
          <p>
            Φ：单位系数（<span>{{ info.productValue }}</span
            >）
          </p>
          <p>
            C：存栏数量（<span>{{ info.num }}</span
            >）
          </p>
          <p>
            L：单位标准值（<span
              >{{ info.standardValue }}{{ info.seasonName }}</span
            >）
          </p>
        </div>
      </div>
    </div>
  </surround-box>
</template>

<script>
import surroundBox from "@/components/mapComp/surroundBox2.vue";
export default {
  name: "",
  components: {
    surroundBox,
  },
  props: {
    menuName: {
      type: String,
      default: "",
    },
    farmId: [String, Number],
  },
  data() {
    return {
      info: {},
    };
  },
  methods: {
    // 查询数据
    getInfo() {
      if (!this.farmId) return;
      this.$post(this.$api.MANURE_DRAIN_MODEL.VIEW, {
        farmId: this.farmId,
      }).then((res) => {
        this.info = res.length > 0 ? res[0] : {};
      });
    },
  },
  mounted() {
    this.getInfo();
  },
};
</script>

<style lang="less" scoped>
@import url("./index.less");
</style>