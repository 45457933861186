<template>
  <surround-box
    :title="menuName"
    :width="440"
    background="rgba(1, 12, 10, 0.8)"
  >
    <div class="pane-inner-container">
      <!-- <div class="select-pane">
        <span>设备列表</span>
        <Select
          v-model="currentDeviceGuid"
          size="small"
          class="select-component"
        >
          <Option :value="item.guid" v-for="item in lists" :key="item.guid">
            {{ item.name }}
          </Option>
        </Select>
      </div> -->
      <div class="weather-pane">
        <template v-if="lists.length > 0">
          <!-- <sensor-data-pane
            v-for="guid in sensorList"
            :key="guid"
            :guid="guid"
            @show-chart="showChart"
          ></sensor-data-pane> -->
          <div class="sensor-item" v-for="(item, index) in lists" :key="index">
            <img :src="item.servicePath + item.imgUrl" alt="" />
            <div>
              <p
                style="
                  text-align: center;
                  font-size: 18px;
                  color: #fff;
                "
              >
                {{ item.measure }}
              </p>
              <p style="color: #fff">
                {{
                  item.sensorCategoryName + "(" + item.sensorCategoryUnit + ")"
                }}
              </p>
            </div>
          </div>
        </template>
        <div class="no-data" v-else>
          <img src="imgs/empty/empty.png" alt="" />
          <p>暂无数据</p>
        </div>
      </div>
      <chart-pane
        v-if="chartPaneData.show"
        :data="chartPaneData.data"
        @closePane="closePane"
      ></chart-pane>
    </div>
  </surround-box>
</template>

<script>
import surroundBox from "@/components/mapComp/surroundBox2";
import sensorDataPane from "./sensorDataPane.vue";
import chartPane from "../chartPane";
export default {
  components: {
    surroundBox,
    sensorDataPane,
    chartPane,
  },
  props: {
    menuName: String,
    currentSiteGuid: String,
    list: {
      type: Array,
      default() {
        return [];
      },
    },
    currentFarm: Object,
    sensorType: [String, Number],
  },
  data() {
    return {
      // 当前选定的设备guid
      currentDeviceGuid: null,
      // 传感器列表
      sensorList: [],
      // 查看曲线数据
      chartPaneData: {
        show: false,
        data: null,
      },
      lists: [],
    };
  },
  watch: {
    menuName() {
      this.reset();
      this.init();
    },
    sensorType: {
      immediate: true,
      handler(val) {
        if (val) {
          this.getList();
        }
      },
    },
  },
  methods: {
    reset() {
      this.currentDeviceGuid = null;
      this.sensorList = [];
      this.chartPaneData = {
        show: false,
        data: null,
      };
    },
    // 初始化
    init() {
      if (this.list.length === 0) return;
      if (this.currentSiteGuid) {
        let device = this.list.find(
          (item) => item.guid == this.currentSiteGuid
        );
        if (device) {
          this.currentDeviceGuid = this.currentSiteGuid;
          if (!device.sensorGuids || !device.sensorNames) {
            this.sensorList = [];
          } else {
            this.sensorList = device.sensorGuids.split(",");
          }
          return;
        }
      }
      let device = this.list[0];
      this.currentDeviceGuid = device.guid;
      if (!device.sensorGuids || !device.sensorNames) {
        this.sensorList = [];
      } else {
        this.sensorList = device.sensorGuids.split(",");
      }
    },
    getList() {
      this.$post(this.$api.MONITOR.TREATMENT_EFFECT_MONITOR, {
        sensorType: this.sensorType,
        farmId: this.currentFarm.sourceId,
      }).then((res) => {
        this.lists = res.list;
      });
    },
    // 展示线形图
    showChart(sensor) {
      let currentDevice = this.list.find(
        (item) => item.guid == this.currentDeviceGuid
      );
      this.chartPaneData.data = {
        ...sensor,
        siteInfo: { ...currentDevice },
        sensorList: [...this.sensorList],
      };
      this.chartPaneData.show = true;
    },
    // 关闭线形图
    closePane() {
      this.chartPaneData.show = false;
    },
  },
  mounted() {
    this.init();
  },
};
</script>

<style lang="less" scoped>
@import url("./index.less");
</style>