<template>
  <div class="content-pane-sensor">
    <template v-if="sensor">
      <img :src="sensor.servicePath + sensor.imgUrl" />
      <p class="val">
        {{
          sensor.measure > Number(sensor.categoryMaxRange)
            ? Number(sensor.categoryMaxRange).toFixed(1)
            : sensor.measure.toFixed(1)
        }}<span>{{ sensor.sensorCategoryUnit }}</span>
      </p>
      <p class="data-name">
        {{ sensor.name
        }}<span v-if="sensor.sensorCategoryRemark">{{
          "(" + sensor.sensorCategoryRemark + ")"
        }}</span>
      </p>
      <p>{{ sensor.updateTime.slice(5) }}</p>
      <a
        class="chartIcon"
        title="查看近期数据曲线"
        @click="() => showChart(sensor)"
      >
        <img src="/imgs/iot/chartIcon.png" />
      </a>
    </template>
  </div>
</template>
<script>
export default {
  props: {
    guid: String,
  },
  data() {
    return {
      sensor: null,
    };
  },
  methods: {
    getSensor() {
      if (!this.guid) return;
      this.$post(this.$api.BUSSDEVICESENSOR.LIST, {
        pageNo: 1,
        pageSize: 999,
        guid: this.guid,
      }).then((res) => {
        if (res.list.length > 0) this.sensor = res.list[0];
      });
    },
    showChart(sensor) {
      this.$emit("show-chart", sensor);
    },
  },
  mounted() {
    this.getSensor();
  },
};
</script>
<style lang='less' scoped>
.content-pane-sensor {
  width: 190px;
  background: rgba(0, 29, 34, 0.5);
  padding: 8px;
  margin-bottom: 10px;
  position: relative;
  overflow: hidden;
  > img {
    position: absolute;
    left: -8px;
    height: 80px;
  }
  p {
    padding-left: 60px;
    color: #dddddd;
    margin-bottom: 2px;
  }
  .val {
    font-size: 20px;
    color: #00ffee;
    span {
      font-size: 14px;
      margin-left: 3px;
    }
  }
  .data-name {
    span {
      font-size: 12px;
    }
  }
  .chartIcon {
    position: absolute;
    top: 10px;
    right: 10px;
    img {
      width: 16px;
    }
  }
}
</style>