<template>
  <div>
    <farm-base-info
      v-if="currentFarm && currentFarm.farmType == 2"
      :currentMapInfo="currentFarm"
    ></farm-base-info>
    <farm-use
      v-if="currentFarm && currentFarm.farmType == 3"
      :current-farm="currentFarm"
    ></farm-use>
  </div>
</template>
<script>
import farmBaseInfo from "./farmBaseInfo.vue";
import farmUse from "./farmUse.vue";

export default {
  props: {
    currentFarm: Object,
  },
  components: {
    farmBaseInfo,
    farmUse,
  },
};
</script>
<style lang='less' scoped>
@import url("./index.less");
</style>