var render = function render(){var _vm=this,_c=_vm._self._c;return _c('surround-box',[_c('div',{staticClass:"menu-container"},[_c('div',{staticClass:"title"},[_vm._v("物联网")]),_c('div',{staticClass:"menu-content"},[_c('div',{staticClass:"menu-title"},[_c('img',{staticClass:"title-icon",attrs:{"src":"/imgs/halo.png"}}),_c('span',[_vm._v("基础信息")])]),_c('div',{staticClass:"menu-list"},[_c('div',{class:['menu-item', _vm.current == 'baseInfo' ? 'current-item' : ''],on:{"click":function($event){return _vm.menuChange('baseInfo')}}},[_vm._v(" 基础信息 ")]),_c('div',{class:[
            'menu-item',
            _vm.current == 'WeatherPrewarning' ? 'current-item' : '',
          ],on:{"click":function($event){return _vm.menuChange('WeatherPrewarning')}}},[_vm._v(" 气象预报 ")]),_c('div',{class:[
            'menu-item',
            _vm.current == 'DeviceManage' ? 'current-item' : '',
          ],on:{"click":function($event){return _vm.menuChange('DeviceManage')}}},[_vm._v(" 处理设备 ")]),_c('div',{class:[
            'menu-item',
            _vm.current == 'Facilities' ? 'current-item' : '',
          ],on:{"click":function($event){return _vm.menuChange('Facilities')}}},[_vm._v(" 处理设施 ")]),(_vm.type == 3)?_c('div',{class:['menu-item', _vm.current == 'ShouNa' ? 'current-item' : ''],on:{"click":function($event){return _vm.menuChange('ShouNa')}}},[_vm._v(" 粪污收纳 ")]):_vm._e(),(_vm.type == 3)?_c('div',{class:['menu-item', _vm.current == 'JieGan' ? 'current-item' : ''],on:{"click":function($event){return _vm.menuChange('JieGan')}}},[_vm._v(" 秸秆收纳 ")]):_vm._e()]),_c('div',{staticClass:"menu-title"},[_c('img',{staticClass:"title-icon",attrs:{"src":"/imgs/halo.png"}}),_c('span',[_vm._v("数据采集")])]),_c('div',{staticClass:"menu-list"},[(_vm.type == 2)?_c('div',{class:['menu-item', _vm.current == 'feces' ? 'current-item' : ''],on:{"click":function($event){return _vm.menuChange('feces')}}},[_vm._v(" 粪污生产 ")]):_vm._e(),_c('div',{class:['menu-item', _vm.current == 'soil' ? 'current-item' : ''],on:{"click":function($event){return _vm.menuChange('soil')}}},[_vm._v(" 土壤墒情 ")]),(_vm.type != 3)?_c('div',{class:['menu-item', _vm.current == 'water' ? 'current-item' : ''],on:{"click":function($event){return _vm.menuChange('water')}}},[_vm._v(" 水体质量 ")]):_vm._e(),_c('div',{class:[
            'menu-item',
            _vm.current == 'videoMonitor' ? 'current-item' : '',
          ],on:{"click":function($event){return _vm.menuChange('videoMonitor')}}},[_vm._v(" 视频监控 ")]),_c('div',{class:[
            'menu-item',
            _vm.current == 'statistics' ? 'current-item' : '',
          ],on:{"click":function($event){return _vm.menuChange('statistics')}}},[_vm._v(" 数据统计 ")]),(_vm.router == 'useMap')?_c('div',{class:['menu-item', _vm.current == 'ZhuaShou' ? 'current-item' : ''],staticStyle:{"width":"68px"},on:{"click":function($event){return _vm.menuChange('ZhuaShou')}}},[_vm._v(" 抓手 ")]):_vm._e(),(_vm.router == 'useMap')?_c('div',{class:['menu-item', _vm.current == 'BaoZhuang' ? 'current-item' : ''],staticStyle:{"width":"68px"},on:{"click":function($event){return _vm.menuChange('BaoZhuang')}}},[_vm._v(" 包装 ")]):_vm._e()]),_c('div',{staticClass:"menu-title"},[_c('img',{staticClass:"title-icon",attrs:{"src":"/imgs/halo.png"}}),_c('span',[_vm._v("数据模型")])]),_c('div',{staticClass:"menu-list"},[(_vm.type == 2)?_c('div',{class:[
            'menu-item',
            _vm.current == 'productModel' ? 'current-item' : '',
          ],on:{"click":function($event){return _vm.menuChange('productModel')}}},[_vm._v(" 生产模型 ")]):_vm._e(),(_vm.type == 2)?_c('div',{class:[
            'menu-item',
            _vm.current == 'sewageModel' ? 'current-item' : '',
          ],on:{"click":function($event){return _vm.menuChange('sewageModel')}}},[_vm._v(" 污水排放 ")]):_vm._e(),_c('div',{class:[
            'menu-item',
            _vm.current == 'landCarryCapacityModel' ? 'current-item' : '',
          ],on:{"click":function($event){return _vm.menuChange('landCarryCapacityModel')}}},[_vm._v(" 土地承载力 ")])]),_c('div',{staticClass:"menu-title"},[_c('img',{staticClass:"title-icon",attrs:{"src":"/imgs/halo.png"}}),_c('span',[_vm._v("分析预警")])]),_c('div',{staticClass:"menu-list"},[_c('div',{class:[
            'menu-item',
            _vm.current == 'equipmentWarn' ? 'current-item' : '',
          ],on:{"click":function($event){return _vm.menuChange('equipmentWarn')}}},[_vm._v(" 设备预警 ")])])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }