<template>
  <div style="width: 100%;height: 100%;position: relative">
    <div v-show="showWindowContent" ref="showWindow" class="show-window window-position" style="position:absolute;">
      <surroundBox background='#223446'>
        <div class="content-box" v-html="showWindowContent"></div>
      </surroundBox>
    </div>
    <div ref="echartMap" class="map-container" style="width: 100%;height: 100%"></div>
  </div>
</template>
<script>
import * as echarts from 'echarts'
import surroundBox from '@/components/mapComp/surroundBox3'
import { mapState } from 'vuex'
import { bd09towgs84 } from '../../../common/util/mapTools'

export default {
  components: {
    surroundBox,
  },
  computed: {
    ...mapState({
      farmReady: (state) => state.map.farmReady,
      farmList: (state) => state.map.farmList,
    }),
  },
  props: {
    //地图放缩级别
    zoom: {
      type: Number,
      default: 1,
    },
    //是否运行拖拽 鼠标缩放和平移漫游。默认不开启
    roam: {
      type: [Boolean, String],
      default: false,
    },
    //中国地图（第一级地图）的ID、Name、Json数据
    areaId: {
      type: String,
      default: '500000',
    },
    //
    mapType: {
      type: Number,
      default: 1, // 1.区域一张图 2.生产
    },
  },
  data() {
    return {
      cityName: '重庆市',
      myEchart: null,
      farmSites: [],
      regions: [], // 特定区域颜色
      showWindowContent: '',
      showWindowContentName: '',
      showWindowContentObj: {},
      flyLines: [], // 飞线
      lineSites: [], // 飞线点
      jisan: [],
      aspectScale: 1,
    }
  },

  methods: {
    //调用接口数据
    getList(cityName, cityJson, id) {
      let cityJsons = require('./json/' + id + '.json')
      this.iconData = []
      if (this.mapType === 1) {
        this.getRegion()
          .then((res) => {
            this.lineSites = res
          })
          .finally((r) => {
            this.setOption(cityName, cityJsons, id)
          })
      } else {
        this.getBasePoint()
          .then((res) => {
            this.farmSites = res
          })
          .finally((r) => {
            this.setOption(cityName, cityJsons, id)
          })

        this.getRegion()
          .then((res) => {
            this.lineSites = res
          })
          .finally((r) => {
            this.setOption(cityName, cityJsons, id)
          })
      }
    },
    //根据Json里的数据构造Echarts地图所需要的数据
    initMapData(mapJson) {
      var mapData = []
      let features = mapJson.features
      let deep = ['500117', '500241', '500101']
      let sort = [
        '500153',
        '500116',
        '500113',
        '500119',
        '500156',
        '500242',
        '500114',
        '500240',
        '500155',
        '500154',
        '500235',
        '500238',
      ]
      this.marks = [deep.length, sort.length, 38 - deep.length - sort.length]
      this.$emit('marks', this.marks)

      for (var i = 0; i < features.length; i++) {
        if (this.in_array(features[i].properties.adcode, deep)) {
          mapData.push({
            name: features[i].properties.name,
            id: features[i].properties.adcode,
            value: 3,
          })
        } else if (this.in_array(features[i].properties.adcode, sort)) {
          mapData.push({
            name: features[i].properties.name,
            id: features[i].properties.adcode,
            value: 2,
          })
        } else {
          mapData.push({
            name: features[i].properties.name,
            id: features[i].properties.adcode,
            value: 1,
          })
        }
      }
      if (this.regions) {
        mapData.map((r) => {
          let cur = this.regions.find((r1) => r1.name === r.name)
          if (cur) {
            r.itemStyle = {
              areaColor: cur.itemStyle.normal.areaColor,
              emphasis: {
                areaColor: cur.itemStyle.emphasis.areaColor,
              },
            }
            r.typeName = 'mapType1Click'
          }
        })
      }
      return mapData
    },
    //验证数组是否存在某元素
    in_array(value, array) {
      for (var i in array) {
        if (array[i] == value) {
          return true
        }
      }
      return false
    },
    mapChart() {
      //设置中间旋转器
      let that = this
      //初始Echarts实例对象
      that.myEchart = echarts.init(that.$refs.echartMap)

      let cityJson = require('./json/' + that.$props.areaId + '.json')
      that.getList(that.cityName, cityJson, that.$props.areaId)
      that.myEchart.on('click', (param) => {
        if (param.data && param.data.typeName === 'mapType1Click') {
          if (this.mapType === 1) {
            // 展示区域信息
            this.showAreaInfo(param)
          }
        } else if (param.seriesName === 'farmSites' && this.mapType === 2) {
          let p = {
            ...param,
          }
          this.$emit('farm-click', p)
        }
      })
    },
    //设置地图参数
    setOption(cityName, cityJson, cityId) {
      echarts.registerMap(cityName, cityJson)
      let series = []
      //图片相关配置信息
      let option = {
        // visualMap: {
        //   show: false,
        //   min: 1,
        //   max: 2,
        //   text: ['High', 'Low'],
        //   realtime: false,
        //   calculable: true,
        //   // inRange: {
        //   //   color: ['#0C775E', '#0C775E', '#0C775E']
        //   // }
        // },
        //地理坐标系组件。地理坐标系组件用于地图的绘制，支持在地理坐标系上绘制散点图，线集。
        geo: {
          show: true,
          map: cityName,
          roam: this.$props.roam,
          zoom: this.$props.zoom + 0.01,
          aspectScale: this.aspectScale,
          itemStyle: {
            normal: {
              opacity: 1, //图形透明度 0 - 1
              borderColor: '#1cccff', //图形的描边颜色
              borderWidth: 3, //描边线宽。为 0 时无描边。
              borderType: 'solid', //柱条的描边类型，默认为实线，支持 'solid', 'dashed', 'dotted'。
              // areaColor: "#333",   //图形的颜色 #eee
              shadowColor: 'rgba(20,80,185,0.96)',
              shadowBlur: 10,
              shadowOffsetX: -10, //X轴阴影
              shadowOffsetY: 20, //Y轴阴影
            },
          },
        },
        //系列列表。每个系列通过 type(map, scatter, bar, line, gauge, tree.....) 决定自己的图表类型
        series: [
          {
            map: cityName,
            type: 'map',
            zoom: this.$props.zoom, //当前视角的缩放比例。
            aspectScale: this.aspectScale,
            roam: this.$props.roam,
            label: {
              show: true,
              color: '#fff',
              fontSize: 12,
              rotate: 0,
            },
            itemStyle: {
              //上层地图信息
              normal: {
                borderWidth: 1, //区域边框宽度
                borderColor: '#1cccff', //区域边框颜色
                // areaColor: 'red',
              },
            },
            emphasis: {
              label: {
                color: '#fff',
                fontSize: 12,
              },
            },
            data: this.initMapData(cityJson),
            //自定义图片数组对象[{}, {}...]
          },
        ],
      }
      //点
      // if (this.mapType === 1) {
      //   for (let i in this.lineSites) {
      //     let item = this.lineSites[i]
      //     option.series.push({
      //       name: 'farmSites',
      //       type: 'scatter',
      //       coordinateSystem: 'geo',
      //       symbolRotate: 0,
      //       //自定义图片的 位置（lng, lat）
      //       data: [
      //         {
      //           name: item.label,
      //           value: item.value,
      //           originData: item.originData,
      //         },
      //       ],
      //       //自定义图片的 大小
      //       symbolSize: [120, 80],
      //       symbolOffset:[0, -32],
      //       //自定义图片的 路径
      //       symbol: `image:///imgs/map/factory3.png`,
      //       label: {
      //         show: true,
      //         formatter: '{b}',
      //         offset: [0, -25],
      //         fontWeight: 'bold',
      //         color: '#fff',
      //         fontSize: 12,
      //         rotate: 0,
      //         width: 118,
      //         overflow: 'truncate',
      //       },
      //     })
      //   }
      // } else if (this.mapType === 2) {
      //   for (let i in this.lineSites) {
      //     let item = this.lineSites[i]
      //     option.series.push({
      //       name: 'lineSites',
      //       type: 'scatter',
      //       coordinateSystem: 'geo',
      //       //自定义图片的 位置（lng, lat）
      //       data: [
      //         {
      //           name: item.label,
      //           value: item.value,
      //           originData: item.originData,
      //         },
      //       ],
      //       //自定义图片的 大小
      //       symbolSize: [30, 30],
      //       //自定义图片的 路径
      //       symbol: `image:///imgs/map/factory.png`,
      //       label: {
      //         formatter: '{b}',
      //         position: 'right',
      //         show: true,
      //         color: '#fff',
      //         fontSize: 15,
      //       },
      //     })
      //   }

      //   for (let i in this.farmSites) {
      //     let item = this.farmSites[i]

      //     let space = 100000000
      //     let longLat = []
      //     this.jisan.forEach((item2) => {
      //       let juli = this.algorithm(item.value, item2[0])
      //       if (space > juli) {
      //         space = juli
      //         longLat = item2[0]
      //       }
      //     })

      //     this.flyLines.push({ coords: [item.value, longLat] })

      //     option.series.push({
      //       name: 'farmSites',
      //       type: 'scatter',
      //       coordinateSystem: 'geo',
      //       //自定义图片的 位置（lng, lat）
      //       data: [
      //         {
      //           name: item.name,
      //           value: item.value,
      //           originData: item.originData,
      //         },
      //       ],
      //       //自定义图片的 大小
      //       symbolSize: item.symbolSize,
      //       //自定义图片的 路径
      //       symbol: item.symbol,
      //       label: {
      //         formatter: '{b}',
      //         position: 'right',
      //         show: true,
      //         color: '#fff',
      //         fontSize: 15,
      //       },
      //     })
      //   }
      // }

      var planePath =
        'path://M1705.06,1318.313v-89.254l-319.9-221.799l0.073-208.063c0.521-84.662-26.629-121.796-63.961-121.491c-37.332-0.305-64.482,36.829-63.961,121.491l0.073,208.063l-319.9,221.799v89.254l330.343-157.288l12.238,241.308l-134.449,92.931l0.531,42.034l175.125-42.917l175.125,42.917l0.531-42.034l-134.449-92.931l12.238-241.308L1705.06,1318.313z'
      // if (this.regions) {
      //   option.geo.regions = this.regions;
      // }
      //车动画
      // if (this.flyLines && this.flyLines.length > 0) {
      //   option.series.push({
      //     type: 'lines',
      //     coordinateSystem: 'geo',
      //     zlevel: 15,
      //     effect: {
      //       show: true,
      //       constantSpeed: 30,
      //       symbol: 'image:///imgs/map/car.png',
      //       symbolSize: [12, 20],
      //       trailLength: 0,
      //       symbolRotate: 180,
      //       loop: true,
      //     },
      //     lineStyle: {
      //       normal: {
      //         color: '#00fff4',
      //         width: 1,
      //         opacity: 0.8,
      //         curveness: 0.1,
      //       },
      //     },
      //     data: this.flyLines,
      //   })
      // }
      this.myEchart.clear()
      option && this.myEchart.setOption(option)
    },
    //	角度转换成弧度
    rad(d) {
      return (d * Math.PI) / 180.0
    },
    //	计算两点之间直线距离
    algorithm(point1, point2) {
      let x1 = point1[0]
      let y1 = point1[1]
      let x2 = point2[0]
      let y2 = point2[1]
      let Lat1 = this.rad(x1) // 纬度
      let Lat2 = this.rad(x2)
      let a = Lat1 - Lat2 //	两点纬度之差
      let b = this.rad(y1) - this.rad(y2) //	经度之差
      let s =
        2 *
        Math.asin(
          Math.sqrt(
            Math.pow(Math.sin(a / 2), 2) +
              Math.cos(Lat1) * Math.cos(Lat2) * Math.pow(Math.sin(b / 2), 2)
          )
        )
      //	计算两点距离的公式
      s = s * 6378137.0 //	弧长等于弧度乘地球半径（半径为米）
      s = Math.round(s * 10000) / 10000 //	精确距离的数值
      return s
    },
    // 查询开发区
    getRegion() {
      return new Promise((resolve, reject) => {
        this.$post(this.$api.BASE_MANAGE.LIST, {
          pageNo: 1,
          pageSize: 9999,
          siteType: '2',
          regionCode: '500153',
          companyNo: 'administrator',
        }).then((res) => {
          initFlyLines(res.list, this)
          let list = res.list.map((r) => {
            if (r.thirdLongLat) {
              let thirdLongLat = r.thirdLongLat.split(',')
              return {
                label: r.name,
                value: [+thirdLongLat[0], +thirdLongLat[1]],
                originData: { ...r },
              }
            }
          })
          resolve(list)
        })
      })

      function initFlyLines(list, that) {
        let centerCoords = []
        let lines = []
        list.forEach((r) => {
          if (r.thirdLongLat) {
            let [lng, lat] = r.thirdLongLat.split(',')
            if (r.name.indexOf('集散中心') > -1) {
              let coords = [[+lng, +lat]]
              lines.push({
                coords: coords,
              })
            } else {
              centerCoords = [+lng, +lat]
            }
            that.jisan.push([[+lng, +lat]])
          }
        })

        lines.map((r) => {
          r.coords.push(centerCoords)
        })
        that.flyLines = lines
      }
    },
    // 初始化特定区域颜色
    initRegions() {
      if (this.mapType === 1 || this.mapType === 2) {
        // 片区分类
        let importantArea = new Set([
          '双河街道',
          '清升镇',
          '清江镇',
          '安富街道',
          '广顺街道',
          '昌州街道',
          '峰高街道',
          '直升镇',
          '路孔镇',
        ])
        let centerArea = new Set([
          '昌元街道',
          '荣隆镇',
          '仁义镇',
          '河包镇',
          '古昌镇',
          '万灵镇',
        ])
        let westArea = new Set(['龙集镇', '盘龙镇', '远觉镇', '清流镇'])
        let northArea = new Set(['吴家镇', '铜鼓镇', '观胜镇'])
        let areas = [importantArea, centerArea, westArea, northArea]
        let regions = []
        areas.forEach((r) => {
          r.forEach((item) => {
            regions.push({
              name: item,
              itemStyle: {
                normal: {
                  areaColor: this.confirmAreaColor(item),
                },
                emphasis: {
                  areaColor: this.confirmAreaColor(item),
                },
              },
            })
          })
        })
        this.regions = regions
        return regions
      }
      return []
    },
    confirmAreaColor(name) {
      let importantArea = new Set([
        '双河街道',
        '清升镇',
        '清江镇',
        '安富街道',
        '广顺街道',
        '昌州街道',
        '峰高街道',
        '直升镇',
        '路孔镇',
      ])
      let centerArea = new Set([
        '昌元街道',
        '荣隆镇',
        '仁义镇',
        '河包镇',
        '古昌镇',
        '万灵镇',
      ])
      let westArea = new Set(['龙集镇', '盘龙镇', '远觉镇', '清流镇'])
      let northArea = new Set(['吴家镇', '铜鼓镇', '观胜镇'])
      if (importantArea.has(name)) {
        return '#0F6A9D'
      }
      if (centerArea.has(name)) {
        return '#235AA6'
      }
      if (northArea.has(name)) {
        return '#1D6AC1'
      }
      if (westArea.has(name)) {
        return '#1D6AC1'
      }
    },
    // 展示区域信息
    showAreaInfo(param) {
      let currentName = param.data.name || ''
      this.showWindowContent = ''
      if (this.showWindowContentName === currentName) {
        this.showWindowContentName = ''
        return
      }
      if (currentName) {
        this.$refs.showWindow.style.top = param.event.offsetY - 200 + 'px'
        this.$refs.showWindow.style.left = param.event.offsetX - 10 + 'px'
        this.showWindowContentName = currentName
        let showData = {
          guimo: '',
          tudi: '',
          bili: '',
        }

        if (this.showWindowContentObj[currentName]) {
          this.showWindowContent =
            this.showWindowContentObj[currentName].content
          this.$emit('area-stat', {
            ...this.showWindowContentObj[currentName].data,
            areaName: param.name,
          })
          return
        }

        this.$post(this.$api.FAMRMING.REGION, {
          regionName: currentName,
        }).then((res) => {
          this.$emit('area-stat', {
            ...res,
            areaName: param.name,
          })
          this.showWindowContent = `
          <div style=" color: #fff;font-size: 14px;padding:4px 0;">
        <span>养殖企业：</span>
        <span>${(res.userFarmers || 0) + '个'}</span>
      </div>
         <div style=" color: #fff;font-size: 14px;padding:4px 0;">
        <span>存栏生猪当量数：</span>
        <span>${(res.nobeis || 0) + '(头)'}</span>
      </div>
         <div style=" color: #fff;font-size: 14px;padding:4px 0;">
        <span>粪污综合利用量：</span>
        <span>${(res.combinedUseOfFecalWaste || 0) + '(m³)'}</span>
      </div>
         <div style=" color: #fff;font-size: 14px;padding:4px 0;">
        <span>粪污产生量：</span>
        <span>${(res.fecalWasteProduction || 0) + '(m³)'}</span>
          `
          this.showWindowContentObj[currentName] = {
            content: this.showWindowContent,
            data: { ...res },
          }
        })
      }
    },

    // 获取基地点
    getBasePoint() {
      return new Promise((resolve, reject) => {
        if (!this.farmReady) {
          this.$store.dispatch('getAllFarms').then((list) => {
            let list1 = list.filter((item) => item.siteType * 1 === 2)
            addFarmDotDo(list1).then((r) => {
              resolve(r)
            })
          })
        } else {
          let list1 = this.farmList.filter((item) => item.siteType * 1 === 2)
          addFarmDotDo(list1).then((r) => {
            resolve(r)
          })
        }
      })

      function addFarmDotDo(list) {
        let value = []
        return new Promise((resolve, reject) => {
          for (let i = 0; i < list.length; i++) {
            let item = list[i]
            if (!item.mapCenterLocation) continue
            let [lng, lat] = item.mapCenterLocation.split(',')
            value.push({
              name: item.mapName,
              symbol: `image:///imgs/map/sitetype${item.siteType}.png`,
              symbolSize: 24,
              value: bd09towgs84(+lng, +lat),
              originData: { ...item },
            })
          }
          resolve(value)
        })
      }
    },
  },
  mounted() {
    this.initRegions()
    this.mapChart()
  },
}
</script>
<style lang="less">
.window-position {
  position: absolute;
  pointer-events: none;
  z-index: 10000;

  .content-box {
    padding: 8px;
    background: #223446;
    border-radius: 4px;
  }

  .left-corner {
    text-align: left;
  }
}
</style>
