import centerOfMass from '@turf/center-of-mass';
import bbox from '@turf/bbox';
import { MAP_PK } from '@/common/constants';
import mapboxgl from 'mapbox-gl';
import coordtransform from 'coordtransform';


mapboxgl.accessToken = MAP_PK;


export function getFeatureCollectionCenter (featureCollection, type) {
  let center = centerOfMass(featureCollection);
  return type === 'feature' ? center : center.geometry.coordinates;
}

export function getFeatureCollectionBounds (featureCollection) {
  let box = bbox(featureCollection);
  return box;
}

export function getMapBounds (sw, ne) {
  let sw_ = new mapboxgl.LngLat(...sw);
  let ne_ = new mapboxgl.LngLat(...ne);
  let bounds = new mapboxgl.LngLatBounds(sw_, ne_);
  return bounds;
}

export function bd09towgs84(lng, lat) {
  let gcj02 = coordtransform.bd09togcj02(lng, lat);
  let wgs84 = coordtransform.gcj02towgs84(gcj02[0], gcj02[1]);
  return wgs84;
}

export function bd09togcj02(lng, lat) {
  let gcj02 = coordtransform.bd09togcj02(lng, lat);
  return gcj02;
}

export function wgs84tobd09(lng, lat) {
  let gcj02 = coordtransform.wgs84togcj02(lng, lat);
  let bd09 = coordtransform.gcj02tobd09(gcj02[0], gcj02[1]);
  return bd09;
}

export function wgs84togcj02(lng, lat) {
  let gcj02 = coordtransform.wgs84togcj02(lng, lat);
  return gcj02;
}

export function gcj02towgs84(lng, lat) {
  let wgs84=coordtransform.gcj02towgs84(lng, lat);
  return wgs84
}

export class AnimateDot {
  constructor (size, map, img) {
    this.width = size;
    this.height = size;
    this.size = size;
    this.map = map;
    this.data = new Uint8Array(size * size * 4);
    this.img = img;
  }

  onAdd () {
    let canvas = document.createElement('canvas');
    canvas.width = this.width;
    canvas.height = this.height;
    this.context = canvas.getContext('2d');
  }

  render () {
    let duration = 1000;
    let t = (performance.now() % duration) / duration;

    let radius = this.size / 2 * 0.3;
    let outerRadius = this.size / 2 * 0.7 * t + radius;
    let context = this.context;

    context.clearRect(0, 0, this.width, this.height);
    context.beginPath();
    context.arc(this.width / 2, this.height / 2, outerRadius, 0, Math.PI * 2);
    context.fillStyle = 'rgba(97, 227, 163,' + (1 - t) + ')';
    context.fill();

    context.beginPath();
    context.arc(this.width / 2, this.height / 2, radius, 0, Math.PI * 2);
    context.fillStyle = 'rgba(97, 227, 163, 1)';
    context.strokeStyle = 'white';
    context.lineWidth = 2 + 4 * (1 - t);
    context.fill();
    context.stroke();

    if (this.img) {
      context.drawImage(this.img, (this.width - 1.414 *radius) / 2, (this.width - 1.414 *radius) / 2, radius*1.414, radius*1.414)
    }

    this.data = context.getImageData(0, 0, this.width, this.height).data;

    this.map.triggerRepaint();
    return true;
  }
}

export class StaticDot {
  constructor (size, map, img) {
    this.width = size;
    this.height = size;
    this.size = size;
    this.map = map;
    this.data = new Uint8Array(size * size * 4);
    this.img = img;
  }

  onAdd () {
    let canvas = document.createElement('canvas');
    canvas.width = this.width;
    canvas.height = this.height;
    this.context = canvas.getContext('2d');
  }

  render () {
    let radius = this.size / 2 * 0.3;
    let context = this.context;

    context.clearRect(0, 0, this.width, this.height);
    context.beginPath();
    context.arc(this.width / 2, this.height / 2, 0, 0, Math.PI * 2);
    context.fillStyle = 'rgba(97, 227, 163,1)';
    context.fill();

    context.beginPath();
    context.arc(this.width / 2, this.height / 2, radius, 0, Math.PI * 2);
    context.fillStyle = 'rgba(97, 227, 163, 1)';
    context.strokeStyle = 'white';
    context.lineWidth = 2;
    context.fill();
    context.stroke();

    if (this.img) {
      context.drawImage(this.img, (this.width - 1.414 *radius) / 2, (this.width - 1.414 *radius) / 2, radius*1.414, radius*1.414)
    }

    this.data = context.getImageData(0, 0, this.width, this.height).data;

    this.map.triggerRepaint();
    return true;
  }
}

export class GifFillPattern {
  constructor (size, map, imgArr) {
    this.width = size;
    this.height = size;
    this.size = size;
    this.map = map;
    this.data = new Uint8Array(size * size * 4);
    this.imgArr = imgArr; // Array
    this.timer = 0;
    this.sort = 0;
  }

  onAdd () {
    let canvas = document.createElement('canvas');
    canvas.width = this.width;
    canvas.height = this.height;
    this.context = canvas.getContext('2d');
  }

  render () {
    let t = performance.now();
    let context = this.context;
    let imgArr = this.imgArr;
    if (this.timer === 0) {
      context.clearRect(0,0,this.width, this.height);
      context.drawImage(imgArr[this.sort++], 0, 0, this.width, this.height);
      if (this.sort >= imgArr.length) this.sort = 0;
      this.timer = t;
      this.data = context.getImageData(0, 0, this.width, this.height).data;
      this.map.triggerRepaint();
      return true;
    }
    if (t - this.timer >= 200) {
      context.clearRect(0,0,this.width, this.height);
      context.drawImage(imgArr[this.sort++], 0, 0, this.width, this.height);
      if (this.sort >= imgArr.length) this.sort = 0;
      this.timer = t;
      this.data = context.getImageData(0, 0, this.width, this.height).data;
      this.map.triggerRepaint();
      return true;
    }
    return false;
  }
}
