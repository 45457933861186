<template>
  <surround-box v-if="weather_info" class="weather">
    <div class="weather-content">
      <div class="condition">
        <img :src="`/imgs/iot/weather/W${weather_info.url}.png`" />
        <span>{{ weather_info.condition }}</span>
      </div>
      <div class="temparature">
        <div>
          <p class="tempnow">
            <span class="iconfont tempnum"
              >{{ weather_info.temp }}&#xe767;</span
            >
            <span>实时</span>
          </p>
          <p style="font-size: 14px" class="iconfont">
            &#xe600;{{ weather_info.city }}
          </p>
        </div>
      </div>
      <div class="other-weather">
        <p>湿度:{{ weather_info.humidity }}%</p>
        <p>{{ weather_info.windDir }} {{ weather_info.windSpeed }}km/h</p>
      </div>
    </div>
    <p class="farm-cut mapModeBox">
      <i style="cursor: pointer" class="iconfont" @click="showBaseInfo"
        >&#xe642;</i
      >
      <span>{{title}}</span>
    </p>
  </surround-box>
</template>

<script>
import surroundBox from "@/components/mapComp/surroundBox";
import CU from "@/common/util";

export default {
  props: {
    lon: [String, Number],
    lat: [String, Number],
    title:String,
    companies: {
      type: Array,
      default() {
        return [];
      },
    },
    currentCompany: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  components: {
    surroundBox,
  },
  data() {
    return {
      weather_info: null,
      tempValue: null,
      tempData: null,
    };
  },
  computed: {
    companyData() {
      return CU.formatTreeData(this.companies, "id", "name", "childList", [
        "companNo",
        "name",
      ]);
    },
  },
  methods: {
    full() {
      try {
        document.body.requestFullscreen()
      } catch (e) {
        console.log(e);
      }
    },
    getWeather() {
      if (!this.lon || !this.lat) {
        this.weather_info = null;
        return;
      }
      this.$post(this.$api.COMMON.WEATHER, {
        type: 2,
        lon: this.lon,
        lat: this.lat,
      }).then((res) => {
        let weather = {
          ...res.condition,
          city: res.city.name,
          url: res.condition.icon,
        };
        this.weather_info = weather;
      });
    },
    selectionCompany(value, selectedData) {
      // this.tempValue = value;
      // this.tempData = selectedData;
      this.$emit("company-select", value, selectedData);
      // let project = this.projects.find((item) => item.id == val);
      // this.$emit("project-select", project);
    },
    emitEvent(flag) {
      if (!flag && this.tempValue && this.tempData) {
        this.$emit("company-select", this.tempValue, this.tempData);
        this.tempValue = null;
        this.tempData = null;
      }
    },
    labelFormat(labels) {
      if (!labels || labels.length === 0) return "";
      return labels[labels.length - 1];
    },
    showBaseInfo() {
      this.$emit("base-click", "baseInfo");
    },
  },
  watch: {
    lon() {
      this.getWeather();
    },
    lat() {
      this.getWeather();
    },
  },
  mounted() {
    this.getWeather();
  },
};
</script>

<style lang='less' scoped>
@import url("./index.less");
</style>
<style>
.map-cover-cascader-transfer .ivu-cascader-menu-item {
  color: #fff;
}
.map-cover-cascader-transfer .ivu-cascader-menu-item:hover {
  color: #565454;
}
</style>