<template>
  <surround-box :width="350" title="信息数据统计">
    <div class="statistics">
      <div class="statistics-content">
        <div class="conts-item">
          <div class="item-box">
            <p class="num">{{ statResult.farmCount }}</p>
            <p>集散点(个)</p>
          </div>
          <div class="item-box">
            <p class="num">{{ statResult.deviceCount }}</p>
            <p>设备(台)</p>
          </div>
          <div class="item-box">
            <p class="num">{{ statResult.facilityCount }}</p>
            <p>设施(台)</p>
          </div>
          <div class="item-box" v-for="(item, index) in statics2" :key="index">
            <p class="num">{{ item.content }}</p>
            <p>{{ item.name + "(" + item.unit + ")" }}</p>
          </div>
        </div>
        <!-- <div class="cont-item">
        <div class="item-box">
          <p class="num">{{ statResult.farmCount }}</p>
          <p>集散点(个)</p>
        </div>
        <div class="item-box">
          <p class="num">{{ statResult.deviceCount }}</p>
          <p>设备(台)</p>
        </div>
      </div>
      <div class="cont-item">
        <div class="item-box">
          <p class="num">{{ statResult.facilityCount }}</p>
          <p>设施(台)</p>
        </div>
        <div class="item-box">
          <p class="num">{{ statResult.usingCapacity }}%</p>
          <p>利用产能</p>
        </div>
      </div>
      <div class="cont-item">
        <div class="item-box">
          <p class="num">{{ statResult.qcfw }}</p>
          <p>畜禽粪污(kg)</p>
        </div>
        <div class="item-box">
          <p class="num">{{ statResult.qcfw }}</p>
          <p>秸秆(kg)</p>
        </div>
      </div>
      <div class="cont-item">
        <div class="item-box">
          <p class="num">{{ statResult.qcfw }}</p>
          <p>其他(kg)</p>
        </div>
        <div class="item-box">
          <p class="num">{{ statResult.qcfw }}</p>
          <p>日产量(kg)</p>
        </div>
      </div>
      <div class="cont-item">
        <div class="item-box">
          <p class="num">{{ statResult.qcfw }}</p>
          <p>库存(kg)</p>
        </div>
      </div> -->
      </div>
    </div>
  </surround-box>
</template>

<script>
import surroundBox from "@/components/mapComp/surroundBox2.vue";
export default {
  components: {
    surroundBox,
  },
  name: "",
  props: {
    currentFarm: Object,
  },
  data() {
    return {
      statResult: {},
      statics2: [],
    };
  },
  watch: {
    currentFarm() {
      this.getStatResult();
      this.getResource2();
    },
  },
  methods: {
    getStatResult() {
      this.$post(this.$api.STATICTICS_MAP.USEING, {
        siteType: "3",
        farmId: this.currentFarm ? this.currentFarm.sourceId : "",
      }).then((res) => {
        this.statResult = res;
      });
    },
    //统计数据
    getResource2() {
      this.$post(this.$api.STATICS_MANAGE.GET4, {
        pid: 1,
        farmId: this.currentFarm ? this.currentFarm.sourceId : "",
      }).then((res) => {
        this.statics2 = res;
      });
    },
  },
  mounted() {
    this.getStatResult();
    this.getResource2();
  },
};
</script>

<style lang="less" scoped>
.statistics {
  width: 100%;
  height: 100%;
  padding: 16px;
  // background: rgba(23, 34, 60, 0.6);
  .statistics-content {
    width: 100%;
    height: 100%;
    // border: 1px solid #d8e0f3;
    border-radius: 8px;
    .item-box {
      width: 50%;
      height: 80px;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      color: #fff;
      .num {
        font-size: 30px;
      }
    }
    .cont-item {
      width: 100%;
      display: flex;
      align-items: center;
    }
    .conts-item {
      height: 100%;
      display: flex;
      align-items: center;
      flex-wrap: wrap;
    }
  }
}
</style>