var render = function render(){var _vm=this,_c=_vm._self._c;return _c('surround-box',{attrs:{"title":_vm.menuName}},[_c('div',{staticClass:"device-account"},[_vm._v("设备总数量："+_vm._s(_vm.devices.length))]),_c('div',{staticClass:"inner-content-page"},_vm._l((_vm.devices),function(device){return _c('div',{key:device.id,staticClass:"device-item",on:{"click":function($event){return _vm.checkDeviceInfo(device)}}},[_c('div',{staticClass:"device-img"},[_c('img',{attrs:{"src":device.img
              ? device.servicePath + device.img
              : device.categoryImg
              ? device.servicePath + device.categoryImg
              : '/imgs/empty/emptyimg.png',"alt":""}})]),_c('div',{staticClass:"device-status"},[_c('span',{class:{
            'device-status-dot': true,
            'rest-device': device.status === 0,
            'working-device': device.status === 1,
            'disabled-device': device.status === 3,
          }}),_vm._v(" "+_vm._s(device.status === 0 ? "闲置中" : device.status === 1 ? "工作中" : "已废弃")+" ")]),_c('div',{staticClass:"device-name"},[_vm._v(" "+_vm._s(device.name)+" ")])])}),0)])
}
var staticRenderFns = []

export { render, staticRenderFns }