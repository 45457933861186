<template>
  <div class="outer-page" style="overflow: hidden">
    <!-- 地图 -->
    <echart-map
      v-show="showMap === 1" 
      :map-type="2" 
      :zoom="1.2" 
      area-id="500153" 
      roam="false" 
      style="background: radial-gradient(circle, #125E7C 0%, #082C3A 100%);"
      @farm-click="farmClickT"
    ></echart-map>

    <produce-map
        ref="map"
        :center="[105.52309962406676,29.456773481066918]"
        :currentMapInfo="currentMapInfo"
        :farmFilter="farmFilter"
        :is-cover="true"
        :zoom="16"
        hideChina
        mapType="normal"
        style="{'opacity': showMap === 2 ? 1 : 0, 'overflow': 'hidden'}"
        @getMapInfo="getMapInfo"
        @farm-change="farmClick"
        @zoom-change="zoomChange"
        @site-click="siteClick"
        @map-ready="mapReady"
    ></produce-map>
    <template v-if="!currentMapInfo">
      <!-- 右侧地区基础信息 -->
      <div class="map-info public-info">
        <base-info v-if="baseInfoShow" @on-change="close"></base-info>
      </div>
      <!-- 左上下拉框 -->
      <div class="map-select public-info">
        <map-select :mapList="mapList" @on-change="getSelectMap"></map-select>
      </div>
      <div class="chulan-info public-info" style="width: auto">
        <chulan-pane v-if="baseInfoShow"></chulan-pane>
      </div>
      <div class="guimo-info public-info" style="width: auto">
        <guimo-pane v-if="baseInfoShow"></guimo-pane>
      </div>
      <div class="produce-info public-info" style="width: auto">
        <produce-pane v-if="baseInfoShow"></produce-pane>
      </div>
      <!-- 第二层左边养殖场基础信息 -->
      <div class="farm-base-info public-info">
        <farm-base-info
            v-show="farmBaseInfoShow"
            :currentMapInfo="currentMapInfo"
        ></farm-base-info>
      </div>
      <!-- 左下显示框 -->
      <div class="num-list public-info">
        <farm-legend :currentFarm="currentMapInfo"></farm-legend>
      </div>
      <!-- 右上基础信息 -->
      <div class="right-base-info public-info">
        <farm-view
            v-show="baseInfoType == 0"
            :currentMapInfo="currentMapInfo"
        ></farm-view>
        <!-- 视频 -->
        <show-video
            v-if="baseInfoType == 1"
            :site="videoSite"
            style="height: 260px"
            @video-close="videoClose"
        ></show-video>
        <!-- 土壤墒情 -->
        <soil-manage
            v-if="baseInfoType == 2"
            :soilInfo="soilInfo"
            @on-close="soilClose"
            @show-chart="showChart"
        ></soil-manage>
      </div>
      <!-- 右下预警信息 -->
      <div class="warning-info public-info">
        <farm-warning
            v-show="farmBaseInfoShow"
            :currentMapInfo="currentMapInfo"
        ></farm-warning>
      </div>
      <!-- 统计图 -->
      <div v-if="chartPaneShow" class="soil-echarts public-info">
        <!-- <soil-echarts></soil-echarts> -->
        <chart-pane :data="chartData" @close-pane="closePane"></chart-pane>
      </div>
    </template>
  </div>
</template>

<script>
import baseInfo from "./component/baseInfo";
import farmBaseInfo from "./component/farmBaseInfo";
import mapSelect from "./component/mapSelect";
import farmLegend from "./component/farmLegend";
import farmView from "./component/farmView";
import farmWarning from "./component/warning";
import showVideo from "./component/showVideo";
import soilManage from "./component/soilManage";
import soilEcharts from "./component/soilEcharts";
import chartPane from "./component/chartPane";
import chulanPane from "./component/chulan";
import guimoPane from "./component/guimo";
import producePane from "./component/produce";
// import produceMap from "./map";
import mapboxMap from "@/components/map/mapbox"
import produceMap from "@/components/map/WlwMap";
import echartMap from "../../../components/map/echartMap/echartMap";

export default {
  name: "",
  components: {
    baseInfo,
    mapSelect,
    produceMap,
    farmBaseInfo,
    farmLegend,
    farmView,
    farmWarning,
    showVideo,
    soilManage,
    soilEcharts,
    chartPane,
    chulanPane,
    guimoPane,
    producePane,
    echartMap,
    mapboxMap
  },
  data() {
    return {
      //基础信息框显示
      baseInfoShow: true,
      //养殖场数量显示
      farmNumShow: true,
      //养殖场基础信息显示窗
      farmBaseInfoShow: false,
      //养殖场下拉框数据
      mapList: [],
      //当前条坐标信息
      currentMapInfo: null,
      //养殖场数量
      farmNum: 0,
      //养殖场图标
      farmImg: "",
      //地图右上基础信息框显示 -- 默认显示基础信息
      baseInfoType: -1,
      //视频信息
      videoSite: null,
      //土壤信息
      soilInfo: null,
      //统计图显示
      chartPaneShow: false,
      //统计信息
      chartData: {},
      showMap: 1, // 1.echart 2.bdmap
      farmFilter: new Set([2, "2"]),
      mapInstance: null
    };
  },
  methods: {
    //关闭右侧信息框
    close() {
      this.baseInfoShow = false;
    },
    //养殖场列表
    getList() {
      this.$post(this.$api.MAP.MAP_CFG_INFO, {
        farmType: 2,
        type: 3,
      })
          .then((res) => {
            this.mapList = res.list;
            this.farmNum = res.list.length;
            this.farmImg =
                res.list[0].servicePath + res.list[0].showPointCoverings;
            if (this.$store.state.user?.user?.roleid) {
              if (+this.$store.state.user.user.roleid === 40) {
                this.getSelectMap(this.mapList[0].id)
              }
            }
          })
          .catch((error) => {
            console.log(error);
          });
    },
    //当前坐标点信息
    getMapInfo(val) {
      this.currentMapInfo = val;
    },
    //下拉框当前条信息
    getSelectMap(val) {
      if (val) {
        this.mapList.filter((item) => {
          if (item.id == val) {
            this.currentMapInfo = item;
            this.showMap = 2
            this.$nextTick(_ => {
              this.$refs.map.$refs.baseMap.farmClick(item);
            })
          }
        });
      }
    },
    farmClick(farm) {
      this.currentMapInfo = farm;
      if (!farm) {
        this.showMap = 1
      }
    },
    farmClickT(param) {
      // console.log(param);
      this.$nextTick(_ => {
        if (!this.mapInstance) {
          this.$Message.warning('地图加载中，请稍后再试')
          return
        }
        this.showMap = 2
        // this.currentMapInfo = param.data.originData
        this.$refs.map.farmClick(param.data.originData)
        this.$refs.map.$refs.mapboxMap.flyToFarm(param.data.value, param.data.originData)
        // this.$refs.mapboxMap.flyToFarm(param.data.value, param.data.originData)
        // this.$refs.map.$refs.baseMap.farmClick(param.data.originData);
      })


    },
    //设备点
    siteClick(site) {
      if (site.siteType == 7) {
        //监控显示
        this.videoSite = site;
        this.baseInfoType = 1;
      }
          //土壤墒情站
          // if (site.siteType == 6) {
          //   this.baseInfoType = 2;
          //   this.soilInfo = site;
      // }
      else {
        this.baseInfoType = 2;
        this.soilInfo = site;
      }
    },
    //关闭视频
    videoClose() {
      this.baseInfoType = -1;
      this.videoSite = null;
    },
    //关闭土壤墒情
    soilClose() {
      this.baseInfoType = -1;
      this.soilInfo = null;
    },
    //统计框
    showChart(data) {
      this.chartPaneShow = true;
      this.chartData = {...data};
    },
    closePane() {
      this.chartPaneShow = false;
      this.chartData = {};
    },
    //地图层级改变
    zoomChange(zoom) {
      if (zoom > 15) {
        this.baseInfoType = 0;
        this.baseInfoShow = false;
        this.farmNumShow = false;
        this.farmBaseInfoShow = true;
      } else {
        this.baseInfoType = -1;
        this.baseInfoShow = true;
        this.farmNumShow = true;
        this.farmBaseInfoShow = false;
        this.currentMapInfo = null;
      }
    },
    // mapbox地图代码部分
    mapReady({map}) {
      console.log('map ready', map)
      this.mapInstance = map
    }
  },
  mounted() {
    this.getList();
  },
};
</script>

<style lang="less" scoped>
.outer-page {
  width: 100%;
  height: 100%;
  position: relative;

  .public-info {
    width: 330px;
    position: absolute;
    z-index: 100;
    margin: 20px;
  }

  .map-info {
    // height: 470px;
    top: 50px;
    left: 0;
  }

  .chulan-info {
    right: 0;
    top: 0;
  }

  .guimo-info {
    right: 0;
    top: 285px;
  }

  .produce-info {
    right: 0;
    top: 570px;
  }

  .map-select {
    top: 0;
    left: 0;
    z-index: 200;
  }

  .farm-base-info {
    height: 470px;
    top: 50px;
    left: 0;
  }

  .num-list {
    bottom: 0;
    left: calc((100% - 330px) / 2);
  }

  .right-base-info {
    top: 0;
    right: 0;
  }

  .warning-info {
    right: 0;
    bottom: 0;
  }

  .soil-echarts {
    width: 700px;
    height: 440px;
    top: 150px;
    left: 50%;
    margin-left: -350px;
  }
}
</style>
