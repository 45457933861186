<template>
  <div class="surround-box">
    <div class="row-one">
      <div :style="{ background: background }" class="column-one"></div>
      <div :style="{ background: background }" class="column-two">
        <span class="border-left-top"></span>
        <span class="border-right-top"></span>
        <span class="border-right-right"></span>
      </div>
    </div>
    <div class="content-box">
      <span class="border-left-top-left"></span>
      <span class="border-left-bottom-left"></span>
      <span class="border-left-bottom-bottom"></span>
      <span class="border-right-bottom-right"></span>
      <span class="border-right-bottom-bottom"></span>
      <slot></slot>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    background: {
      type: String,
      default: "none",
    },
  },
};
</script>
<style lang='less' scoped>
.surround-box {
  .row-one {
    display: flex;
    height: 8px;
    overflow: hidden;
    position: relative;
    .column-one {
      width: 16px;
      height: 16px;
      position: absolute;
      left: 0;
      bottom: 0;
      border-left: 2px solid #00fff7;
      transform: rotate(45deg);
      transform-origin: left bottom;
    }
    .column-two {
      width: calc(100% - 8px);
      height: 100%;
      position: absolute;
      right: 0;
      top: 0;
      .border-left-top {
        position: absolute;
        width: 8px;
        height: 2px;
        background: #00fff7;
        left: 0;
        top: 0;
        z-index: 999;
      }
      .border-right-top {
        position: absolute;
        width: 8px;
        height: 2px;
        background: #00fff7;
        right: 0;
        top: 0;
        z-index: 999;
      }
      .border-right-right {
        position: absolute;
        width: 2px;
        height: 8px;
        background: #00fff7;
        right: 0;
        top: 0;
        z-index: 999;
      }
    }
  }
  .content-box {
    position: relative;
    .border-left-top-left {
      position: absolute;
      width: 2px;
      height: 8px;
      background: #00fff7;
      left: 0;
      top: 0;
      z-index: 999;
    }
    .border-left-bottom-left {
      position: absolute;
      width: 2px;
      height: 8px;
      background: #00fff7;
      left: 0;
      bottom: 0;
      z-index: 999;
    }
    .border-left-bottom-bottom {
      position: absolute;
      width: 8px;
      height: 2px;
      background: #00fff7;
      left: 0;
      bottom: 0;
      z-index: 999;
    }
    .border-right-bottom-right {
      position: absolute;
      width: 2px;
      height: 8px;
      background: #00fff7;
      right: 0;
      bottom: 0;
      z-index: 999;
    }
    .border-right-bottom-bottom {
      position: absolute;
      width: 8px;
      height: 2px;
      background: #00fff7;
      right: 0;
      bottom: 0;
      z-index: 999;
    }
  }
}
</style>