<template>
  <div class="outer-page">
    <div class="title">
      <!-- <span class="title-icon"></span> -->
      基本信息
    </div>
    <div class="detail-info">
      <span>养殖户编码：{{detailData.code}}</span>
      <span>养殖户姓名：{{detailData.chargePerson}}</span>
      <span>联 系 电 话：{{detailData.chargePersonTel}}</span>
      <span>联 系 地 址：{{detailData.regionCodeName}}</span>
      <span>养 殖 地 址：{{detailData.regionCodeName}}</span>
      <span>坐标经纬度：{{lat+"，"+long}}</span>
      <span>存 栏 信 息</span>
        <table>
          <th>
              <td v-for="(item,index) in expandList" :key="index">{{item.categoryName}}</td>
          </th>
          <tr>
              <td v-for="(item,index) in expandList" :key="index">{{item.num}}</td>
          </tr>
        </table>
    </div>
    <div class="left-top-corner"></div>
    <div class="right-top-corner"></div>
    <div class="right-bottom-corner"></div>
    <div class="left-bottom-corner"></div>
  </div>
</template>

<script>
export default {
  name: "",
  props: {
    currentMapInfo: Object,
  },
  data() {
    return {
      detailData: [],
      lat: "",
      long: "",
      //存栏量
      expandList: [],
    };
  },
  methods: {
    //养殖场详情
    getFarmDetail() {
      this.$post(this.$api.BASE_MANAGE.INFO, {
        id: this.currentMapInfo ? this.currentMapInfo.sourceId : "",
      }).then((res) => {
        this.detailData = res;
        this.expandList = res.bussSceneExpand;
        let a = "";
        let b = "";
        if (this.detailData.thirdLongLat) {
          a = this.detailData.thirdLongLat.split(",")[0];
          b = this.detailData.thirdLongLat.split(",")[1];
        }
        this.lat = Number(a).toFixed(2);
        this.long = Number(b).toFixed(2);
      });
    },
  },
  watch: {
    currentMapInfo(n) {
      if (n == null) return;
      this.getFarmDetail();
    },
  },
};
</script>

<style lang="less" scoped>
@import url("./index.less");
.outer-page {
  width: 100%;
  height: 100%;
  // background: rgba(23, 34, 60, 0.6);
  background: linear-gradient(
    270deg,
    rgba(14, 56, 50, 0.28) 0%,
    rgba(3, 42, 34, 0.81) 100%
  );
  border-radius: 4px;
  color: #fff;
  // padding: 10px;
  .title {
    height: 40px;
    text-align: center;
    line-height: 40px;
    // display: flex;
    font-size: 17px;
    font-weight: bold;
    color: #ffffff;
    // margin-bottom: 10px;
    background: linear-gradient(
      270deg,
      rgba(255, 255, 255, 0) 0%,
      rgba(194, 226, 245, 0) 12%,
      #10a6bd 50%,
      rgba(210, 233, 247, 0) 90%,
      rgba(255, 255, 255, 0) 100%
    );
    // .title-icon {
    //   &::before {
    //     content: "";
    //     display: inline-block;
    //     width: 10px;
    //     height: 16px;
    //     background: linear-gradient(180deg, #06baa1 0%, #edff48 100%);
    //     border-radius: 5px;
    //     margin-right: 10px;
    //     margin-top: 7px;
    //   }
    // }
  }
  .detail-info {
    padding: 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    span {
      display: inline-block;
      line-height: 30px;
    }
    table {
      display: flex;
      th,
      tr {
        display: flex;
        flex-direction: column;
        td {
          display: inline-block;
          width: 155px;
          height: 50px;
          text-align: center;
          line-height: 50px;
          border: 1px solid #fff;
          border-bottom: 0;
          &:last-child {
            border-bottom: 1px solid #fff;
          }
        }
      }
      tr {
        td {
          border-left: 0;
        }
      }
    }
  }
}
</style>